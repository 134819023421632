import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function SetAnnoPlacing(selected: boolean) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_268)">
        <path
          d="M19.6856 21.4631H8.10784C7.34225 21.4631 6.71929 20.8401 6.71929 20.0745V19.3458H7.50289V20.0745C7.50289 20.4076 7.77402 20.6795 8.10784 20.6795H19.6856C20.0186 20.6795 20.2906 20.4084 20.2906 20.0745V4.59363C20.2906 4.24336 20.0053 3.95734 19.6543 3.95734H18.4695V3.17374H19.6543C20.4371 3.17374 21.0742 3.81081 21.0742 4.59363V20.0745C21.0742 20.8401 20.4512 21.4631 19.6856 21.4631Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.935 0H9.04865C8.59181 0 8.1624 0.177095 7.83798 0.499941L3.45371 4.8756C3.16534 5.1624 3 5.5589 3 5.96559V16.9008C3 17.6664 3.62297 18.2894 4.38855 18.2894H15.9663C16.7319 18.2894 17.3549 17.6664 17.3549 16.9008V1.41989C17.3549 0.637072 16.7178 0 15.935 0ZM8.39043 1.05473C8.40218 1.04298 8.41472 1.03279 8.42725 1.02182V5.11851C8.42725 5.31912 8.26426 5.48211 8.06366 5.48211H3.95992C3.97481 5.46408 3.99048 5.44685 4.00693 5.43039L8.39043 1.05473ZM16.5705 16.9008C16.5705 17.2338 16.2994 17.5058 15.9655 17.5058H4.38855C4.05552 17.5058 3.78361 17.2346 3.78361 16.9008V6.26571H8.06366C8.69603 6.26571 9.21086 5.75088 9.21086 5.11851V0.783606H15.935C16.2853 0.783606 16.5713 1.06884 16.5713 1.41989V16.9008H16.5705Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M14.2698 4.48855H10.4545V5.27215H14.2698V4.48855Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M14.2703 7.66228H6.1059V8.44589H14.2703V7.66228Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M14.2703 10.9645H6.1059V11.7481H14.2703V10.9645Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M14.2703 14.0747H6.1059V14.8583H14.2703V14.0747Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.6174 14.4052L17.9902 21.5227L18.9838 18.6696L21.8056 21.6833L22.7671 20.6889L19.7533 17.7394L22.7671 16.8093L15.6174 14.4052Z"
          stroke="#231815"
        />
        <path
          d="M15.6174 14.4052L17.9902 21.5227L18.9838 18.6696L21.8056 21.6833L22.7671 20.6889L19.7533 17.7394L22.7671 16.8093L15.6174 14.4052Z"
          fill={selected ? '#609DF9' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_268">
          <rect width="23" height="24" fill={selected ? '#609DF9' : 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function SetAnnoPlacingIcon(
  props: Partial<CustomIconComponentProps> & { selected: boolean }
) {
  const { selected, ...rest } = props
  return <Icon component={() => SetAnnoPlacing(selected)} {...rest} />
}
