import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import clsx from 'clsx'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingFilesState,
  listPointState,
  revalidateFileListState
} from 'store/buildingStore'
import useSWR from 'swr'
import {
  BuildingChildrenFolder,
  BuildingFile,
  Folders,
  ListBuildingFiles
} from 'types/building'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import AddFolderIcon from 'components/icons/AddFolderIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import MoreIcon from 'components/icons/MoreIcon'

import Image360Detail from './360ImageDetail'
import BuildingFileDetail from './BuildingFileDetail'
import ChildrenFolderList from './ChildrenFolderList'
import UploadFileMenu from './MenuUploadFile'
import ModalAddOrEditChildrenFolder from './ModalAddOrEditChildrenFolder'
import ModalDelete360Image from './ModalDelete360Image'
import ModalDeleteFile from './ModalDeleteFile'

type Props = {
  handleBack: (() => void) | undefined
  selectedFolder: { doc: Folders; subDoc?: Folders } | undefined
}

export default function InstructionMenu({ handleBack, selectedFolder }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [selectImage, setSelectImage] = useState<number>()
  const [uploadFileStep, setUploadFileStep] = useState(false)
  const [isAddingFolder, setIsAddingFolder] = useState(false)
  const [deleteFile, setDeleteFile] = useState<BuildingFile | undefined>(
    undefined
  )
  const [confirmDelete, setConfirmDelete] = useState<
    | {
        id: number
        name: string
      }
    | undefined
  >()
  const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<number>()

  const points = useRecoilValue(listPointState)
  const [buildingFiles, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [revalidateFileList, setRevalidateFileList] = useRecoilState(
    revalidateFileListState
  )

  const sorted360Images = useMemo(
    () =>
      buildingFiles.files
        .map((file) => {
          const pointInFloor = points.find(
            (point) =>
              point.id === file.pointId || point.image360?.id === file.id
          )
          // file is used in other floor if file tied to a point and that point is not current floor
          const isInFloor = Boolean(!floorId || pointInFloor || !file.pointId)
          return {
            ...file,
            isInFloor,
            pointOrder: pointInFloor?.order || 0
          }
        })
        .sort((a, b) => {
          if (a.isInFloor && b.isInFloor) {
            return !a.pointOrder && !b.pointOrder
              ? a.name.localeCompare(b.name)
              : a.pointOrder - b.pointOrder
          }
          return +b.isInFloor - +a.isInFloor
        }),
    [buildingFiles, floorId, points]
  )

  const sortedBuildingFiles = useMemo(
    () => [...buildingFiles.files].sort((a, b) => a.name.localeCompare(b.name)),
    [buildingFiles]
  )

  const sortedCondition = useMemo(
    () =>
      selectedFolder && selectedFolder.doc.isFileHolder
        ? selectedFolder.doc.id
        : selectedFolder?.subDoc?.id,
    [selectedFolder]
  )

  const { data, isValidating, mutate } = useSWR<ListBuildingFiles>({
    url: 'v1/buildings/files',
    params: {
      buildingId: id,
      floorId,
      category: sortedCondition
    }
  })

  const {
    data: childrenFolderFiles,
    isValidating: isValidatingChildrenFolderFiles,
    mutate: mutateChildrenFolder
  } = useSWR<BuildingChildrenFolder>(
    selectedChildrenFolder
      ? {
          url: `v1/buildings/${id}/folders/${selectedChildrenFolder}`
        }
      : null
  )

  const sortedChildrenFolderFiles = useMemo(
    () =>
      childrenFolderFiles?.files.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      ) || [],
    [childrenFolderFiles?.files]
  )

  const handleSwitchMenu = () => {
    setUploadFileStep((prev) => !prev)
  }

  const handleSelectImage = (id: number) => {
    if (selectImage === id) {
      setSelectImage(undefined)
      return
    }
    setSelectImage(id)
  }

  const handleToggleDeleteModal = (
    key: { id: number; name: string } | undefined
  ) => {
    setConfirmDelete(key)
  }

  const handleToggleDeleteFile = (file: BuildingFile | undefined) => {
    setDeleteFile(file)
  }

  const handleSetRevalidateByCategory = () => {
    setRevalidateFileList(sortedCondition)
  }

  const handleMenuBack = () => {
    if (selectedChildrenFolder) {
      setSelectedChildrenFolder(undefined)
      return
    }
    handleBack && handleBack()
  }

  const handleOpenMenu = () => {
    if (!isValidatingChildrenFolderFiles) {
      setIsAddingFolder(true)
    }
  }

  useEffect(() => {
    if (data?.files && !isValidating) {
      setBuildingFiles(data.files)
    }
  }, [data?.files, isValidating, setBuildingFiles])

  useEffect(() => {
    if (revalidateFileList) {
      if (revalidateFileList === sortedCondition) {
        mutate()
      }
      setRevalidateFileList(undefined)
    }
  }, [mutate, revalidateFileList, setRevalidateFileList, sortedCondition])

  useEffect(() => {
    if (revalidateFileList) {
      if (revalidateFileList === selectedChildrenFolder) {
        mutateChildrenFolder()
      }
      setRevalidateFileList(undefined)
    }
  }, [
    mutateChildrenFolder,
    revalidateFileList,
    selectedChildrenFolder,
    setRevalidateFileList
  ])

  return (
    <>
      <div className="w-full grid grid-rows-[1fr_auto] overflow-y-auto">
        {uploadFileStep ? (
          <UploadFileMenu
            documentBreadcrumb={selectedFolder}
            handleSwitchMenu={handleSwitchMenu}
            selectedFolder={selectedFolder}
            folderId={selectedChildrenFolder}
          />
        ) : (
          <>
            <div className="grid grid-rows-[auto_1fr] overflow-auto">
              {selectedFolder?.doc.id !== 1 && (
                <div className="flex items-center gap-3 min-h-[50px] p-[10px] border-0 border-b border-solid border-[#1C314D]">
                  <div
                    className="flex gap-3 items-center cursor-pointer"
                    onClick={handleMenuBack}
                  >
                    <div className="flex-shrink-0">
                      <LeftArrowIcon />
                    </div>
                    <Typography className="text-white font-[700] text-[14px] my-auto overflow-hidden">
                      {selectedFolder?.doc?.label}
                      {!!selectedFolder?.subDoc && (
                        <>&nbsp;&gt;&nbsp;{selectedFolder.subDoc.label}</>
                      )}
                      {!isValidatingChildrenFolderFiles &&
                        !!selectedChildrenFolder && (
                          <>&nbsp;&gt;&nbsp;{childrenFolderFiles?.name}</>
                        )}
                    </Typography>
                  </div>
                  {selectedFolder?.doc.id === 41 && !selectedChildrenFolder ? (
                    <div
                      className="w-5 h-5 flex rounded-full bg-[#609DF9] cursor-pointer ml-auto"
                      onClick={handleOpenMenu}
                    >
                      <AddFolderIcon className="m-auto" />
                    </div>
                  ) : null}
                  {selectedFolder?.doc.id === 41 && selectedChildrenFolder ? (
                    <div
                      className="w-5 h-5 flex rounded-full cursor-pointer ml-auto"
                      onClick={handleOpenMenu}
                    >
                      <MoreIcon className="m-auto" />
                    </div>
                  ) : null}
                </div>
              )}

              <div
                className={clsx('overflow-auto relative', {
                  'opacity-50': isValidating
                })}
              >
                {selectedFolder?.doc.id !== 1 &&
                !isValidating &&
                !selectedChildrenFolder ? (
                  <>
                    <ChildrenFolderList
                      categoryId={sortedCondition}
                      setSelectedChildrenFolder={setSelectedChildrenFolder}
                    />
                    {sortedBuildingFiles.map((item: BuildingFile) => (
                      <BuildingFileDetail
                        item={item}
                        handleToggleDeleteFile={handleToggleDeleteFile}
                        key={item.id}
                      />
                    ))}
                  </>
                ) : null}

                {selectedFolder?.doc.id !== 1 &&
                !isValidatingChildrenFolderFiles &&
                selectedChildrenFolder
                  ? sortedChildrenFolderFiles.map((item: BuildingFile) => (
                      <BuildingFileDetail
                        item={item}
                        handleToggleDeleteFile={handleToggleDeleteFile}
                        key={item.id}
                      />
                    ))
                  : null}

                {selectedFolder?.doc.id === 1 && !isValidating
                  ? sorted360Images.map((item) => (
                      <Image360Detail
                        item={item}
                        key={item.id}
                        handleSelectImage={handleSelectImage}
                        selectImage={selectImage}
                        isInFloor={item.isInFloor}
                        pointOrder={item.pointOrder}
                        confirmDelete={handleToggleDeleteModal}
                      />
                    ))
                  : null}

                {!isValidating &&
                !buildingFiles.files.length &&
                !buildingFiles.folders ? (
                  <Typography className="mt-[14px] text-center text-[13px] leading-[19.5px] text-white">
                    {t('building.fileNotFound')}
                  </Typography>
                ) : null}
              </div>
            </div>

            {isValidating && <Spinner />}

            <div className="flex h-[3.75rem] border-0 border-t border-solid border-[#1C314D]">
              <Button
                type="primary"
                className="mt-[10px] m-auto"
                sizing="h-[40px] w-[160px]"
                onClick={handleSwitchMenu}
              >
                {t('common.upload')}
              </Button>
            </div>
          </>
        )}
      </div>

      {deleteFile && (
        <ModalDeleteFile
          handleToggleDeleteFile={handleToggleDeleteFile}
          handleSetRevalidateByCategory={handleSetRevalidateByCategory}
          item={deleteFile}
          selectedChildrenFolder={selectedChildrenFolder}
        />
      )}

      {confirmDelete && (
        <ModalDelete360Image
          selectedFile={confirmDelete}
          handleToggle={handleToggleDeleteModal}
          handleSetRevalidateByCategory={handleSetRevalidateByCategory}
        />
      )}

      {isAddingFolder && sortedCondition && (
        <ModalAddOrEditChildrenFolder
          categoryId={sortedCondition}
          selectedFolderName={childrenFolderFiles?.name}
          selectedChildrenFolder={selectedChildrenFolder}
          setSelectedChildrenFolder={setSelectedChildrenFolder}
          setIsAddingFolder={setIsAddingFolder}
          revalidate={mutate}
          revalidateChildrenFolder={mutateChildrenFolder}
        />
      )}
    </>
  )
}
