import { atom } from 'recoil'

type Props = {
  isLocked: boolean
  lockedBy: any
}

export const displayToggleState = atom<Props>({
  key: 'displayToggle',
  default: {
    isLocked: false,
    lockedBy: ''
  }
})

// control open status of both side menu due to multiple component needed
export const currentOpenMenu = atom<{
  isOpenLeftMenu: boolean
  isOpenRightMenu: boolean
}>({
  key: 'currentOpened',
  default: {
    isOpenLeftMenu: true,
    isOpenRightMenu: false
  }
})
