import { PropsWithChildren, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Building } from 'models/Building'
import LeftBarDetailBuilding from 'pages/building/buildingDetail/components/MenuLeftDetailBuilding'
import RightBarDetailBuilding from 'pages/building/buildingDetail/components/MenuRightDetailBuilding'
import PointVersionMenu from 'pages/building/floor/area/PointVersion/PointVersionMenu'
import Preview360 from 'pages/building/panoramas/components/Preview360'
import { useRecoilState, useRecoilValue } from 'recoil'
import { buildingModeState } from 'store/buildingStore'
import { currentOpenMenu } from 'store/displayToggleStore'
import useSWR from 'swr'
import { BuildingStatus, UserAction } from 'types/building'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'
import ShowBarIcon from 'components/icons/ShowBarIcon'
import HeaderDetailBuilding from 'components/organisms/Header/HeaderDetailBuilding'

export default function DetailBuildingLayout({ children }: PropsWithChildren) {
  const { id } = useParams()
  const navigate = useNavigate()

  const buildingMode = useRecoilValue(buildingModeState)
  const [{ isOpenLeftMenu, isOpenRightMenu }, setIsOpenMenu] =
    useRecoilState(currentOpenMenu)

  const handleToggleLeftSidebar = () => {
    setIsOpenMenu((prev) => ({ ...prev, isOpenLeftMenu: !isOpenLeftMenu }))
  }

  const handleToggleRightSidebar = () => {
    setIsOpenMenu((prev) => ({
      ...prev,
      isOpenRightMenu: !isOpenRightMenu
    }))
  }

  const { data: building, isValidating } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  const handleMouseDown = () => {
    const historyToggler = document.getElementById('version-history-toggler')
    const pointActionsMenuContainer = document.getElementById(
      'point-action-menu-rightest-element'
    )
    const container = document.querySelector<HTMLElement>(
      '#detail-building-container'
    )
    const rightSidebar = document.querySelector<HTMLElement>(
      '#detail-building-right-sidebar'
    )
    const resize = (e: any) => {
      const containerSize = container!.offsetWidth
      const size = containerSize - e.x - 4
      if (rightSidebar && size < 260) {
        rightSidebar.style.width = `260px`
      }
      if (rightSidebar && size >= 260 && size <= 420) {
        rightSidebar.style.width = `${size}px`
      }
      if (rightSidebar && size > 420) {
        rightSidebar.style.width = `420px`
      }

      if (historyToggler && pointActionsMenuContainer) {
        const togglerBound = historyToggler.getBoundingClientRect()
        const containerBound = pointActionsMenuContainer.getBoundingClientRect()
        if (containerBound.right + 5 >= togglerBound.left) {
          historyToggler.style.top = '98px'
          historyToggler.style.left = '-82px'
        }
        if (containerBound.right + 35 < togglerBound.left) {
          historyToggler.style.top = '20px'
          historyToggler.style.left = '-112px'
        }
      }
    }

    document.addEventListener('mousemove', resize, false)
    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', resize, false)
      },
      false
    )
  }

  useEffect(() => {
    if (
      building?.action === UserAction.VIEW &&
      building?.status === BuildingStatus.PUBLIC
    ) {
      navigate(`/viewer/buildings/${id}`)
    }
  }, [building?.action, building?.status, id, navigate])

  return (
    <Spinner spinning={isValidating}>
      <div
        id="detail-building-container"
        className="h-screen grid grid-rows-[auto_1fr]"
      >
        <div className="h-[60px] relative">
          <HeaderDetailBuilding building={building} />
        </div>

        <div className="flex overflow-hidden">
          <div className="w-full h-full bg-[#F9FBFF] relative">
            {/* left sidebar menu */}
            {/* z-index increase by 50 when children component need z-index */}
            <div className="absolute top-0 bottom-0 left-0 flex z-[750]">
              {/* toggle left menu button */}
              <div
                className="absolute cursor-pointer top-[10px] -right-10"
                onClick={handleToggleLeftSidebar}
              >
                <ShowBarIcon className={!isOpenLeftMenu ? 'rotate-180' : ''} />
              </div>
              {/* left menu */}
              <div
                className={`h-full bg-[#162841] overflow-auto ${
                  isOpenLeftMenu ? 'min-w-[240px]' : 'w-0'
                }`}
              >
                <LeftBarDetailBuilding building={building} />
              </div>
            </div>

            {/* middle content */}
            <div className="flex overflow-y-auto h-full">{children}</div>

            {/* right sidebar menu */}
            <div className="absolute top-0 bottom-0 right-0 flex z-[700] select-none">
              {/* right menu */}
              <div
                className="absolute top-[10px] -left-10 cursor-pointer"
                onClick={handleToggleRightSidebar}
              >
                {/* <FileMenuIcon isOpen={isOpenRightMenu} /> */}
                <ShowBarIcon className={!isOpenRightMenu ? '' : 'rotate-180'} />
              </div>
              {/* preview 360 window */}
              <Preview360 />
              {/* right menu */}
              {isOpenRightMenu && (
                <>
                  <div
                    className="w-[4px] cursor-ew-resize z-[600] bg-[#3B3C46] relative"
                    onMouseDown={handleMouseDown}
                  >
                    <div className="absolute top-1/2 -translate-y-1/2 w-[3px] h-[19px] bg-[#A6AAAF] rounded-[20px] -right-2" />
                  </div>
                  <div
                    id="detail-building-right-sidebar"
                    className="bg-[#162841] min-w-[260px] max-w-[420px] w-[260px] select-none"
                  >
                    <RightBarDetailBuilding />
                  </div>
                </>
              )}
              {/* point version menu */}
              {buildingMode.previewMode ? <PointVersionMenu /> : null}
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  )
}
