import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, Input, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

type Props = {
  categoryId: number
  selectedFolderName: string | undefined
  selectedChildrenFolder: number | undefined
  setSelectedChildrenFolder: Dispatch<SetStateAction<number | undefined>>
  setIsAddingFolder: Dispatch<SetStateAction<boolean>>
  revalidate: () => void
  revalidateChildrenFolder: () => void
}

export default function ModalAddOrEditChildrenFolder({
  categoryId,
  selectedFolderName,
  selectedChildrenFolder,
  setSelectedChildrenFolder,
  setIsAddingFolder,
  revalidate,
  revalidateChildrenFolder
}: Props) {
  const { id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)

  const { trigger, isMutating } = useMutation(`/v1/buildings/${id}/folders`)

  const handleAddOrEditFolder = (values: any) => {
    if (!isMutating) {
      trigger(
        {
          method: selectedChildrenFolder ? 'patch' : 'post',
          data: {
            categoryId: selectedChildrenFolder ? undefined : categoryId,
            name: values.fileName
          },
          query: selectedChildrenFolder ? [selectedChildrenFolder] : undefined,
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            if (selectedChildrenFolder) {
              revalidateChildrenFolder()
            }
            revalidate()
            setIsAddingFolder(false)
          },
          onError: () => {
            setIsAddingFolder(false)
          }
        }
      )
    }
  }

  const handleDeleteChildrenFolder = () => {
    if (!isMutating) {
      trigger(
        {
          method: 'delete',
          data: null,
          query: [selectedChildrenFolder],
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            revalidate()
            setSelectedChildrenFolder(undefined)
            setIsAddingFolder(false)
          },
          onError: () => {
            setIsAddingFolder(false)
          }
        }
      )
    }
  }

  const handleCancel = () => {
    setIsAddingFolder(false)
  }

  return (
    <Modal width={isOpenModalDelete ? 480 : 620}>
      <div className="text-center flex flex-col gap-10 items-center">
        <Typography className="text-[20px] font-[700] text-[#3163B7]">
          {isOpenModalDelete && t('translation.deleteConfirm')}
          {!isOpenModalDelete &&
            (selectedChildrenFolder
              ? t('translation.createFolder')
              : t('translation.fileNameEdit'))}
          <hr className="h-[1px] bg-[#3163B7] border-none w-[40px]" />
        </Typography>
        {isOpenModalDelete ? (
          <>
            <div className="text-black text-sm">
              {t('translation.deleteFolderConfirm')}
            </div>
            <div className="bg-[#F9FBFF] w-full rounded-[10px] p-[18px] text-center">
              {selectedFolderName}
            </div>
            <div className="flex gap-10 justify-center w-full">
              <Button
                sizing="w-[140px] h-[50px]"
                onClick={() => setIsOpenModalDelete(false)}
              >
                {t('common.cancel')}
              </Button>

              <Button
                sizing="w-[140px] h-[50px]"
                className="bg-[#B80808]"
                onClick={handleDeleteChildrenFolder}
              >
                {t('translation.delete')}
              </Button>
            </div>
          </>
        ) : (
          <Form
            form={form}
            colon={false}
            initialValues={{
              fileName: selectedChildrenFolder ? selectedFolderName : ''
            }}
            onFinish={handleAddOrEditFolder}
          >
            <div className="w-[420px] mx-auto">
              <Form.Item
                name="fileName"
                className="input-floor thin-error-message"
                label={t('translation.fileName')}
                rules={[
                  {
                    validator: Validators.keywordsAndLengthCustomValidator(
                      t,
                      200,
                      true,
                      true
                    )
                  }
                ]}
              >
                <Input
                  className="w-[300px] h-[38px] text-[14px] font-[400]"
                  placeholder={t('common.floor.placeHolder')}
                />
              </Form.Item>
            </div>
            <div className="flex gap-10 justify-center w-full items-end h-full mt-10">
              <Button sizing="w-[140px] h-[50px]" onClick={handleCancel}>
                {t('common.cancel')}
              </Button>

              {selectedChildrenFolder ? (
                <Button
                  sizing="w-[140px] h-[50px]"
                  className="bg-[#B80808]"
                  onClick={() => setIsOpenModalDelete(true)}
                >
                  {t('translation.delete')}
                </Button>
              ) : null}

              <Button
                type="primary"
                sizing="w-[140px] h-[50px]"
                htmlType="submit"
              >
                {t('common.confirm')}
              </Button>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  )
}
