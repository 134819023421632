import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function ChildrenFolderSvg() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9375 6.78125C1.9375 6.01046 2.24369 5.27125 2.78872 4.72622C3.33375 4.18119 4.07296 3.875 4.84375 3.875H10.199C12.0551 3.875 13.609 4.96 14.6766 6.169C15.4709 7.068 16.43 7.75 17.4375 7.75H26.1562C26.927 7.75 27.6663 8.05619 28.2113 8.60122C28.7563 9.14625 29.0625 9.88546 29.0625 10.6562V24.2188C29.0625 24.9895 28.7563 25.7288 28.2113 26.2738C27.6663 26.8188 26.927 27.125 26.1562 27.125H4.84375C4.07296 27.125 3.33375 26.8188 2.78872 26.2738C2.24369 25.7288 1.9375 24.9895 1.9375 24.2188V6.78125ZM4.84375 5.8125C4.58682 5.8125 4.34042 5.91456 4.15874 6.09624C3.97706 6.27792 3.875 6.52432 3.875 6.78125V11.625H27.125V10.6562C27.125 10.3993 27.0229 10.1529 26.8413 9.97124C26.6596 9.78956 26.4132 9.6875 26.1562 9.6875H17.4375C15.5697 9.6875 14.1244 8.46881 13.2254 7.45162C12.3496 6.45962 11.2762 5.8125 10.199 5.8125H4.84375ZM27.125 13.5625H3.875V24.2188C3.875 24.4757 3.97706 24.7221 4.15874 24.9038C4.34042 25.0854 4.58682 25.1875 4.84375 25.1875H26.1562C26.4132 25.1875 26.6596 25.0854 26.8413 24.9038C27.0229 24.7221 27.125 24.4757 27.125 24.2188V13.5625Z"
        fill="#5D6266"
      />
    </svg>
  )
}

export default function ChildrenFolderIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={ChildrenFolderSvg} {...props} />
}
