import { useNavigate, useParams } from 'react-router-dom'
import { Building } from 'models/Building'
import ViewerLeftBarDetailBuilding from 'pages/building/buildingDetailViewMode/components/ViewerLeftBarDetailBuilding'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'
import ViewerHeader from 'components/organisms/Header/ViewerHeader'

import AnnotationModal from './components/AnnotationModal'
import ViewerMiniMap from './components/ViewerMiniMap'
import ViewerPanorama from './components/ViewerPanorama'

export default function ViewerBuildingDetail() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const currentSelected = useRecoilValue(currentAnnotation)

  const { data: building, isValidating } = useSWR<Building>(
    {
      url: `/v1/buildings/${id}`
    },
    {
      onSuccess: (data) => {
        setCurrentViewer((prev) => {
          if (prev.selectedFloor !== null) {
            const isFloorCurrent = data.floors.findIndex(
              (item) => item.id === prev.selectedFloor
            )
            if (isFloorCurrent !== -1) {
              return prev
            }
          }
          const storedFloor = localStorage.getItem('floor')
          if (storedFloor) localStorage.removeItem('floor')
          return {
            ...prev,
            selectedFloor: storedFloor ? +storedFloor : data.floors[0].id
          }
        })
      },
      onError: (err) => {
        Utils.handleErrorNavigate(err, navigate)
      }
    }
  )

  return (
    <Spinner spinning={isValidating}>
      <div className="h-screen grid grid-rows-[auto_1fr] overflow-x-hidden">
        <ViewerHeader building={building} />

        <div className="relative overflow-y-auto">
          <ViewerLeftBarDetailBuilding floors={building?.floors} />
          {currentViewer.selectedPoint ? <ViewerPanorama /> : null}
          {currentViewer.isOpenMiniMap ? <ViewerMiniMap /> : null}
        </div>

        {currentSelected ? <AnnotationModal /> : null}
      </div>
    </Spinner>
  )
}
