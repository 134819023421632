import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Form, Input, Select, Typography } from 'antd'
import useSWR from 'swr'
import { SearchUser, SelectOrganization } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  handleSearch: (value: SearchUser) => void
  listRole: string[]
}
export default function FormSearch({ handleSearch, listRole }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [searchParams] = useSearchParams()

  const formatedSearchParams = useMemo(() => {
    const serializedParams = Object.fromEntries(searchParams.entries())
    return {
      ...serializedParams,
      organizationId: serializedParams?.organizationId
        ? +serializedParams.organizationId
        : null
    }
  }, [searchParams])

  const { data: organizations } = useSWR<SelectOrganization[]>({
    url: '/v1/organizations/users'
  })

  const onSearch = (values: any) => {
    handleSearch(
      Utils.trimObjectValues({
        ...values,
        organizationId: values.organizationId
      })
    )
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  useEffect(() => {
    if (organizations) {
      form.setFieldsValue(formatedSearchParams)
    }
  }, [form, formatedSearchParams, organizations])

  return (
    <Form
      layout="horizontal"
      className="font-semibold w-full"
      onFinish={onSearch}
      colon={false}
      form={form}
    >
      <div className="flex gap-[30px] w-full">
        <Form.Item
          label={
            <Typography className="text-sm">{t('user.keyword')}</Typography>
          }
          name="keyword"
          className="flex mb-0 item-center label-custom-select thin-placeholder custom-error-message"
          rules={[{ validator: Validators.keywordValidator(t) }]}
        >
          <Input className="min-w-[180px] max-w-[180px] h-[38px] text-sm" />
        </Form.Item>

        <Form.Item
          label={
            <Typography className="text-sm">
              {t('user.organization')}
            </Typography>
          }
          name="organizationId"
          className="flex items-center mb-0 label-custom-select thin-placeholder custom-error-message"
        >
          <Select
            className="min-w-[180px] max-w-[180px] h-[38px]"
            placeholder={t('organization.placeholderUserOrganizationID')}
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
            options={[
              {
                value: '',
                label: null
              },
              ...(organizations || []).map((item) => ({
                value: item.id,
                label: <span className="text-sm">{item.name}</span>
              }))
            ]}
          />
        </Form.Item>

        <Form.Item
          className="flex items-center mb-0 label-custom-select thin-placeholder"
          label={<Typography className="text-sm">{t('user.role')}</Typography>}
          name="role"
        >
          <Select
            className="min-w-[180px] max-w-[180px] h-[38px]"
            placeholder={t('user.all')}
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
            options={[
              {
                value: '',
                label: <span className="text-sm">{t('user.all')}</span>
              },
              ...listRole.map((role) => ({
                value: role,
                label: <span className="text-sm">{t(`user.${role}`)}</span>
              }))
            ]}
          />
        </Form.Item>

        <div className="ml-20">
          <Button type="primary" sizing="w-[160px] h-[40px]" htmlType="submit">
            {t('user.buttonSearch')}
          </Button>
        </div>
      </div>
    </Form>
  )
}
