import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPoint,
  selectedPointDetail,
  setAnnotationModeState
} from 'store/buildingStore'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

export default function ModalDeleteSetAnnotation() {
  const { id, floorId } = useParams()
  const { t } = useTranslation()

  const [
    { selectedSetAnnotation, annotationId, setAnnotationDetail },
    setSelectedMode
  ] = useRecoilState(setAnnotationModeState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const pointDetail = useRecoilValue(selectedPointDetail)

  const { trigger: deleteSetAnnotation, isMutating: isDeletingSetAnnotation } =
    useMutation(`v1/buildings/${id}/set-annotations/${selectedSetAnnotation}`)
  const { trigger: deleteAnnotation, isMutating: isDeletingAnnotation } =
    useMutation(`v1/annotations`)

  const handleCloseModal = (isDeleting?: boolean) => {
    if (isDeleting) {
      setSelectedMode({
        annotationId: undefined,
        mode: annotationId ? undefined : 'listSetAnnotation',
        setAnnotationDetail: undefined,
        selectedSetAnnotation: undefined
      })
      return
    }
    setSelectedMode((prev) => ({
      ...prev,
      mode: annotationId ? 'editSetAnnotation' : 'addSetAnnotation'
    }))
  }

  const removeRelatedAnnotations = (setAnnotationId: number | undefined) => {
    const krpano = document.getElementById('embedpano-full') as any

    pointDetail?.annotations.forEach((item) => {
      if (item.set_annotation_id === setAnnotationId) {
        krpano?.call(`remove_annotation(${item.id})`)
      }
    })
  }

  const handleDeleteAnnotation = () => {
    const krpano = document.getElementById('embedpano-full') as any
    if (!isDeletingAnnotation) {
      deleteAnnotation(
        {
          method: 'delete',
          query: [annotationId],
          params: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint
          },
          data: null,
          successMessage: t('building.deleted')
        },
        {
          onSuccess: () => {
            krpano.call(`remove_annotation(${annotationId});`)
            handleCloseModal(true)
          },
          onError: () => {
            handleCloseModal(true)
          }
        }
      )
    }
  }

  const handleDeleteSetAnnotation = () => {
    if (!isDeletingSetAnnotation) {
      deleteSetAnnotation(
        {
          method: 'delete',
          data: null
        },
        {
          onSuccess: () => {
            removeRelatedAnnotations(selectedSetAnnotation)
            handleCloseModal(true)
          },
          onError: () => {
            handleCloseModal(true)
          }
        }
      )
    }
  }

  const handleSubmitDelete = () => {
    if (annotationId) {
      handleDeleteAnnotation()
      return
    }
    handleDeleteSetAnnotation()
  }

  return (
    <Modal width={480} isEnterable onEnter={handleSubmitDelete}>
      <Title
        title={t(
          !annotationId
            ? 'building.deleteSetAnnotation'
            : 'common.titleDeleteAnnotation'
        )}
      />

      <Typography className="text-sm text-center mb-5">
        {t(
          !annotationId
            ? 'building.deleteSetAnnotationConfirm'
            : 'building.deleteConfirmation'
        )}
      </Typography>

      {!annotationId ? (
        <Typography className="text-sm text-center">
          {t('building.deleteAllAnnotationWarning')}
        </Typography>
      ) : null}

      {!annotationId ? (
        <div className="rounded-[10px] bg-[#F9FBFF] p-[18px] text-center my-10">
          {setAnnotationDetail?.name}
        </div>
      ) : null}

      <div className="flex gap-10 justify-center">
        <Button sizing="w-[140px] h-[50px]" onClick={() => handleCloseModal()}>
          {t('building.buttonCancel')}
        </Button>
        <Button
          className="bg-[#B80808]"
          sizing="w-[140px] h-[50px]"
          onClick={handleSubmitDelete}
        >
          {t('common.delete')}
        </Button>
      </div>
    </Modal>
  )
}
