import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Form, Input } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'

type Props = {
  handleSearch: (value: SearchUser) => void
}
export default function FormSearch({ handleSearch }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('keyword')

  const onSearch = (values: any) => {
    handleSearch(values)
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  useEffect(() => {
    form.setFieldsValue({ keyword })
  }, [form, keyword])

  return (
    <Form
      layout="horizontal"
      className="flex gap-20 font-medium"
      onFinish={onSearch}
      colon={false}
      initialValues={{ role: '' }}
      form={form}
    >
      <Form.Item
        label={<Typography className="text-sm">{t('user.keyword')}</Typography>}
        className="flex justify-center mb-0 items-center label-custom-select thin-placeholder thin-error-message"
        name="keyword"
        rules={[{ validator: Validators.keywordValidator(t) }]}
      >
        <Input className="w-[580px] h-[38px] text-sm" />
      </Form.Item>

      <Form.Item className="flex mb-0 justify-end items-center">
        <Button
          type="primary"
          className="mr-10"
          sizing="w-[160px] h-[40px]"
          htmlType="submit"
        >
          {t('user.buttonSearch')}
        </Button>
      </Form.Item>
    </Form>
  )
}
