import { Annotation } from 'types/building'
import { Direction, Stairway } from 'types/krpano'

const Krpano = class Krpano {
  static ConvertMemo = (memo: string) => {
    const entities: any = {
      '/': '&#x2F;',
      '\n': '<br/>',
      ',': '&#x2C;',
      '<': '&lt;',
      '>': '&gt;'
    }
    const text = memo
      .split('')
      .map((item: any) => (entities[item] ? entities[item] : item))
      .join('')
    return text
  }

  static AddDirectionArrow = (
    krpano: any,
    directions: Direction[],
    hgap: number
  ) => {
    const addingHgap = hgap > 180 ? 360 - hgap : hgap
    directions.forEach((direction) => {
      krpano.call(
        `add_direction(${direction.id},${direction.h + addingHgap},${
          direction.v
        },${direction.target},${direction.point_to})`
      )
    })
  }

  // handle add annotation
  static AddAnnotation = (krpano: any, annotations: Annotation[]) => {
    annotations.forEach((annotation) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, head_h, head_v, mid_h, mid_v, h, v, type, memo } = {
        ...annotation
      }
      krpano.call(
        `add_annotation(${id},${head_h},${head_v},${mid_h},${mid_v},${h},${v},${type},${this.ConvertMemo(
          memo || ''
        )},${annotation.set_annotation_id});`
      )
    })
  }

  static UnmountPanoFull = () => {
    const krpano = document.getElementById('embedpano-full') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-full')
    }
  }

  static UnmountPanoPreview = () => {
    const krpano = document.getElementById('embedpano-preview') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-preview')
    }
  }

  static UnmountPanoViewer = () => {
    const krpano = document.getElementById('embedpano-viewer') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-viewer')
    }
  }

  static RemoveTempAnnotation = () => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano?.call('hide_temp_hotspot();')
  }

  static UnmountFull = () => {
    this.UnmountPanoFull()
    this.UnmountPanoPreview()
  }

  static GetAngle = (dx: any, dy: any) => {
    const d = (Math.atan2(dy, dx) * 180) / Math.PI
    if (d < 0) {
      return d + 360
    }
    return d
  }

  static TogglePointLock = (isLocked?: boolean) => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano?.call(`set(islocked,${isLocked || false});`)
  }

  static LoadChecking = (callback: () => void) => {
    const fullpanoLoading = (
      document.getElementById('embedpano-full') as any
    )?.get('loadcomplete')
    const previewpanoLoading = (
      document.getElementById('embedpano-preview') as any
    )?.get('loadcomplete')
    if (
      (fullpanoLoading === undefined && previewpanoLoading === undefined) ||
      (fullpanoLoading === 'true' && previewpanoLoading === 'true')
    ) {
      callback()
    }
  }

  static NormalizeHgap = (hgap: number): number => {
    if (hgap >= 360) {
      return this.NormalizeHgap(hgap - 360)
    }
    if (hgap < 0) {
      return this.NormalizeHgap(hgap + 360)
    }
    return hgap
  }

  static AddStairway = (krpano: any, stairways: Stairway[]) => {
    if (krpano) {
      stairways.forEach((item) => {
        const newTooltip = item?.nextFloorName
          ? `<p>フロア：${
              item.nextFloorName.length > 10
                ? `${item.nextFloorName?.slice(0, 10)}...`
                : item.nextFloorName
            }</p>`
          : ''
        krpano.call(
          `add_stairway(${item.id}, ${item.h}, ${item.v}, ${item.nextFloorId}, ${item.nextPointId}, ${newTooltip})`
        )
      })
    }
  }

  static RevalidateDirection = (directions: Direction[], hgap: number) => {
    const krpano = document.getElementById('embedpano-full') as any

    const addingHgap = hgap > 180 ? 360 - hgap : hgap
    directions.forEach((item) => {
      krpano.call(`set(hotspot[arrow-${item.id}].ath, ${item.h + addingHgap});`)
      krpano.call(
        `set(hotspot[tooltip-${item.id}].ath, ${item.h + addingHgap});`
      )
    })
  }
}

export default Krpano
