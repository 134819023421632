import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function AddFolderSvg() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20589_162733)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.3694 3H1.6409C1.53672 2.99999 1.43367 3.02169 1.33834 3.06372C1.24301 3.10574 1.15747 3.16717 1.08719 3.24408C1.01691 3.32099 0.96343 3.4117 0.930151 3.51043C0.896871 3.60916 0.884526 3.71374 0.893903 3.8175L1.37165 9.0675C1.38849 9.25385 1.47438 9.42717 1.61245 9.55344C1.75052 9.67972 1.9308 9.74982 2.1179 9.75H6.7484V10.5H2.1179C1.74368 10.4999 1.38303 10.3598 1.10675 10.1074C0.830472 9.855 0.65852 9.50844 0.624653 9.13575L0.146903 3.88575C0.114943 3.53834 0.205699 3.1907 0.403403 2.90325L0.373403 2.25C0.373403 1.85218 0.531438 1.47064 0.812743 1.18934C1.09405 0.908035 1.47558 0.75 1.8734 0.75H4.6274C5.0252 0.750085 5.40666 0.908176 5.6879 1.1895L6.3089 1.8105C6.59014 2.09182 6.97161 2.24992 7.3694 2.25H10.3559C10.5643 2.24998 10.7705 2.2934 10.9612 2.37749C11.1519 2.46159 11.3231 2.58451 11.4636 2.73842C11.6042 2.89232 11.7111 3.07384 11.7777 3.27138C11.8442 3.46893 11.8688 3.67817 11.8499 3.88575L11.6579 6H10.9034L11.1022 3.8175C11.1115 3.7138 11.0992 3.60929 11.066 3.51061C11.0327 3.41193 10.9793 3.32126 10.9091 3.24436C10.8389 3.16746 10.7535 3.10602 10.6582 3.06395C10.563 3.02188 10.46 3.0001 10.3559 3H7.3694ZM5.1569 1.71975L5.68865 2.25H1.6409C1.4609 2.25 1.2884 2.2815 1.1279 2.34L1.1234 2.235C1.12733 2.0387 1.20808 1.85176 1.34831 1.71434C1.48854 1.57692 1.67706 1.49996 1.8734 1.5H4.6274C4.8263 1.50004 5.01703 1.57909 5.15765 1.71975H5.1569Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.125 7.5C10.2245 7.5 10.3198 7.53951 10.3902 7.60983C10.4605 7.68016 10.5 7.77554 10.5 7.875V9H11.625C11.7245 9 11.8198 9.03951 11.8902 9.10983C11.9605 9.18016 12 9.27554 12 9.375C12 9.47446 11.9605 9.56984 11.8902 9.64017C11.8198 9.71049 11.7245 9.75 11.625 9.75H10.5V10.875C10.5 10.9745 10.4605 11.0698 10.3902 11.1402C10.3198 11.2105 10.2245 11.25 10.125 11.25C10.0255 11.25 9.93016 11.2105 9.85983 11.1402C9.78951 11.0698 9.75 10.9745 9.75 10.875V9.75H8.625C8.52554 9.75 8.43016 9.71049 8.35983 9.64017C8.28951 9.56984 8.25 9.47446 8.25 9.375C8.25 9.27554 8.28951 9.18016 8.35983 9.10983C8.43016 9.03951 8.52554 9 8.625 9H9.75V7.875C9.75 7.77554 9.78951 7.68016 9.85983 7.60983C9.93016 7.53951 10.0255 7.5 10.125 7.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20589_162733">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function AddFolderIcon(
  props: Partial<CustomIconComponentProps>
) {
  return <Icon component={AddFolderSvg} {...props} />
}
