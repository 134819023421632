import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Checkbox, Typography } from 'antd'
import useSWR from 'swr'
import {
  BuildingChildrenFolder,
  BuildingFile,
  ListBuildingFiles
} from 'types/building'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'
import ChildrenFolderIcon from 'components/icons/ChildrenFolderIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import PreviewFile from './PreviewFile'

type Props = {
  folder?: {
    id: number
    label: string
  }
  selectedChildrenFolder?: {
    id: number
    label: string
  }
  selectedFiles: BuildingFile[]
  handleSelectFile: (file: BuildingFile) => void
  handleCloseFileSelectMenu: (clear?: boolean) => void
}

export default function FileList({
  folder,
  selectedChildrenFolder,
  selectedFiles,
  handleSelectFile,
  handleCloseFileSelectMenu
}: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const [selectedFolder, setSelectedFolder] = useState<{
    id: number
    name: string
  }>()

  const { data, isValidating } = useSWR<ListBuildingFiles>(
    selectedChildrenFolder
      ? {
          url: 'v1/buildings/files',
          params: {
            buildingId: id,
            category: selectedChildrenFolder.id
          }
        }
      : null
  )

  const { data: folderFiles } = useSWR<BuildingChildrenFolder>(
    selectedFolder
      ? {
          url: `v1/buildings/${id}/folders/${selectedFolder.id}`
        }
      : null
  )

  const sortedFolders = useMemo(
    () => [...(data?.files.folders || [])].sort((a, b) => a.order - b.order),
    [data?.files]
  )

  const sortedFiles = useMemo(
    () =>
      [...(data?.files.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [data?.files]
  )

  const sortedFolderFiles = useMemo(
    () =>
      [...(folderFiles?.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [folderFiles?.files]
  )

  return (
    <Spinner spinning={isValidating}>
      <Title
        title={`${folder?.label}${'　'}${selectedChildrenFolder?.label}`}
        height={30}
      />
      <div className="h-[360px] overflow-auto">
        {selectedFolder ? (
          <>
            <div
              className="h-10 flex gap-5 cursor-pointer border-0 border-b border-solid border-[#EEEFF2]"
              onClick={() => setSelectedFolder(undefined)}
            >
              <div className="my-auto">
                <LeftArrowIcon color="#021120" />
              </div>
              <div className="text-[#021220] text-[18px] leading-[16px] font-medium mt-[10px]">
                {selectedFolder.name}
              </div>
            </div>
            {sortedFolderFiles.map((item, index) => (
              <div
                key={item.id}
                className={`grid grid-cols-[auto_auto_1fr] items-center gap-5 px-5 min-h-[60px] cursor-pointer border-0 border-b ${
                  index === 0 ? 'border-t' : ''
                } border-solid border-[#EEEFF2]`}
                onClick={() => handleSelectFile(item)}
              >
                <Checkbox
                  checked={Boolean(
                    selectedFiles.find((file) => file.id === item.id)
                  )}
                />
                <PreviewFile file={item} />
                <Typography className="text-[#021120] leading-[23.17px] font-medium">
                  {item.name}
                </Typography>
              </div>
            ))}
          </>
        ) : (
          <>
            {sortedFolders.map((item) => (
              <div
                key={item.id}
                className="h-[60px] flex gap-10 items-center px-10 border-0 border-b border-solid border-[#EEEFF2] cursor-pointer"
                onClick={() =>
                  setSelectedFolder({ id: item.id, name: item.name })
                }
              >
                <ChildrenFolderIcon />
                <div className="text-base text-[#021120]">{item.name}</div>
              </div>
            ))}
            {sortedFiles.map((item, index) => (
              <div
                key={item.id}
                className={`grid grid-cols-[auto_auto_1fr] items-center gap-5 px-5 min-h-[60px] cursor-pointer border-0 border-b ${
                  index === 0 ? 'border-t' : ''
                } border-solid border-[#EEEFF2]`}
                onClick={() => handleSelectFile(item)}
              >
                <Checkbox
                  checked={Boolean(
                    selectedFiles.find((file) => file.id === item.id)
                  )}
                />
                <PreviewFile file={item} />
                <Typography className="text-[#021120] leading-[23.17px] font-medium">
                  {item.name}
                </Typography>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="flex w-full pt-5 mt-7 justify-center gap-10 border-0 border-t border-solid border-[#EEEFF2]">
        <Button
          sizing="w-[140px] h-[50px]"
          onClick={() => handleCloseFileSelectMenu(true)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          sizing="w-[140px] h-[50px]"
          className="bg-[#3163B7]"
          onClick={() => handleCloseFileSelectMenu()}
        >
          {t('translation.buttonSelect')}
        </Button>
      </div>
    </Spinner>
  )
}

FileList.defaultProps = {
  folder: '',
  selectedChildrenFolder: undefined
}
