import { UploadFile } from 'antd'

import { Stairway } from './krpano'

type Direction = {
  id: string
  h: number
  v: number
  point_to: string
}

export type Folders = {
  label: string
  isFileHolder: boolean
  id: number
  children: Folders[] | null
}

export type BuildingFile = {
  id: number
  s3Path: string
  mimeType: string
  extension: string
  createdAt: string
  name: string
  category?: number
  thumbnail: string
  pointId: string | null
  takenAt: string | null
  isRelated?: boolean
  order?: number
}

export type ListBuildingFiles = {
  files: {
    files: BuildingFile[]
    folders: { id: number; name: string; order: number }[]
  }
}

export type BuildingChildrenFolder = {
  id: number
  name: string
  order: number
  files: BuildingFile[]
}

export type PreSignList = {
  key: string
  url: string
}

export type UploadingFiles = {
  id?: number
  uploadStatus: UploadStatus
  file: UploadFile<any>
  abortSignal?: AbortController
}

export enum UploadStatus {
  LOADING = 'loading',
  FAIL = 'fail',
  SUCCESS = 'success',
  DEFAULT = 'default'
}

export type SelectedFiles = {
  id: number
  buildingId: string
  category: number
  files: UploadingFiles[]
}

export enum BuildingLeftMenu {
  MENU = 'menu',
  INFORMATION = 'information',
  FLOOR = 'floor',
  AREA = 'area',
  EXPORT_IMAGES = 'exportImages'
}

export type AreaDetail = {
  id: number
  name: string
  order: number
}

export type FloorDetail = {
  id: number
  name: string
  mapFile: BuildingFile
  areas: AreaDetail[]
}

export type LockedByUser = {
  id: number
  email: string
  firstName: string
  firstNameKana: string
  lastName: string
  lastNameKana: string
}

export type Point = {
  id: string
  areaId: number
  floorId: number
  order: number
  x: number
  y: number
  image360: BuildingFile | null
  annotations: Annotation[]
  initView: InitView
  directions?: Direction[]
  isLocked: boolean
  lockedByUser: LockedByUser | null
  lockedBy: number | null
  stairways?: Stairway[]
  versionId?: string
  isSubImages: boolean
  subImages?: BuildingFile[]
}

export type InitView = {
  v: number
  h: number
  z: number
  radarHGap: number
}

export type Link = {
  id: string
  point1: {
    id: string
    x: number
    y: number
  }
  point2: {
    id: string
    x: number
    y: number
  }
}

export type FloorMapElement = {
  defaultPoint: {
    areaId: number
    id: string
  } | null
  points: Point[]
  links: Link[]
}

export type Annotation = {
  id?: string
  building_id?: number
  floor_id?: number
  point_id?: string
  files?: BuildingFile[]
  name?: string
  type?: AnnotationType
  head_h?: number
  head_v?: number
  mid_h?: number
  mid_v?: number
  h?: number
  v?: number
  link?: string
  memo?: string
  set_annotation_id?: number
}

export enum AnnotationType {
  ARCHITECTURE = 10,
  AIR_CONDITION = 20,
  ELECTRICITY = 30,
  MEMO = 40,
  DOCUMENT = 41
}

export type OpenModalDelete = {
  isOpen: string
  pointId: string
  pointOrder: number
}

export type LinkAnnotation = {
  link?: string
  name?: string
}

export enum SelectedMode {
  Point = 'point',
  Link = 'link'
}

export enum BuildingStatus {
  EDIT = 10,
  PUBLIC = 20,
  PRIVATE = 30,
  IMAGE = 40
}

export enum UserAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  FULL = 'FULL'
}

export type ImageFloor = {
  id: string
  order: number
  thumbnail: string
  isDefault: boolean
}

export type ListImagesFloor = {
  edges: ImageFloor[]
  pagination: {
    page: number
    pageSize: number
    total: number
  }
}

export const FileMagicNumber = {
  VIDEO: ['00014', '00018', '00020', '0001c'],
  IMAGE: ['ffd8', '89504e47'],
  PDF: ['25504446']
}

export enum DropdownMenuKey {
  HANDLE_INFORMATION = 'handleInformation',
  HANDLE_EDIT_INFORMATION = 'handleEditInformation',
  HANDLE_EDIT_STATUS = 'handleEditStatus',
  HANDLE_SHARE_BUILDING = 'handleShareBuildingModal',
  HANDLE_EXPORT_IMAGE = 'handleExportImage',
  HANDLE_DELETE_BUILDING = 'handleDeleteBuilding'
}

export type FloorPointElement = {
  id: number
  name: string
  order: number
  totalPoint: number
  totalImage360: number
}

export type FloorPointList = {
  floors: FloorPointElement[]
  totalPointBuilding: number
  totalImage360Building: number
}

export type RelatedPoint = {
  floorId: number
  name: string
  order: number
  point: {
    id: string
    areaId: number
    x: number
    y: number
    order: number
    image360: BuildingFile | null
    initView: InitView
  }
}

export interface ListRelatedPoints {
  pagination: {
    page: number
    pageSize: number
    total: number
  }
  edges: RelatedPoint[]
}

export type PointHistory = {
  editedAt: string
  user: {
    email: string
    firstName: string
    firstNameKana: string
    id: number
    isDeleted: boolean
    lastName: string
    lastNameKana: string
  }
  versionId: string
  timePeriod?: string
  isCurrentYear?: boolean
  isWithinWeek?: boolean
}

export type PointHistoryList = {
  edges: PointHistory[]
  pagination: {
    page: number
    pageSize: number
    total: number
  }
}

export type BuildingCSV = {
  id: number
  name: string
  organizationName: string
  status: number
  branchName: string
  branchStoreName: string
  createdAt: string
  latestUpdated: string
  designerName: string
  architectName: string
  constructionPeriodStart: string
  constructionPeriodEnd: string
  siteArea: string
  buildingArea: string
  totalFloorArea: string
  structure: string
  floorNumber: string
  code: string
  aliasName: string
  zipcode: string
  prefCode: number
  cityName: string
  streetName: string
  buildingName: string
  isDeleted: boolean
  fileCount: number
}

export type SetAnnotation = {
  id?: number
  link: string
  memo: string
  type: AnnotationType
  name: string
  linkName: string
  files: BuildingFile[]
}
