import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function SetAnnoCreate(selected: boolean) {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_268)">
        <path
          d="M21.1299 22.0326H9.24571C8.45986 22.0326 7.82041 21.3931 7.82041 20.6073V19.8592H8.62475V20.6073C8.62475 20.9491 8.90306 21.2282 9.24571 21.2282H21.1299C21.4717 21.2282 21.7508 20.9499 21.7508 20.6073V4.71666C21.7508 4.35712 21.4581 4.06354 21.0977 4.06354H19.8815V3.25919H21.0977C21.9013 3.25919 22.5552 3.91313 22.5552 4.71666V20.6073C22.5552 21.3931 21.9157 22.0326 21.1299 22.0326Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M17.2773 0H10.2087C9.7398 0 9.29902 0.181782 8.96602 0.513172L4.46572 5.00463C4.16972 5.29821 4 5.70602 4 6.12347V17.3481C4 18.1339 4.63945 18.7734 5.4253 18.7734H17.3095C18.0953 18.7734 18.7348 18.1339 18.7348 17.3481V1.45747C18.7348 0.653932 18.0808 0 17.2773 0ZM9.53308 1.08265C9.54515 1.07058 9.55802 1.06013 9.57089 1.04886V5.25398C9.57089 5.45989 9.40358 5.62719 9.19767 5.62719H4.98532C5.0006 5.60869 5.01669 5.591 5.03358 5.5741L9.53308 1.08265ZM17.9296 17.3481C17.9296 17.6899 17.6513 17.969 17.3087 17.969H5.4253C5.08345 17.969 4.80434 17.6907 4.80434 17.3481V6.43153H9.19767C9.84678 6.43153 10.3752 5.90308 10.3752 5.25398V0.804344H17.2773C17.6368 0.804344 17.9304 1.09713 17.9304 1.45747V17.3481H17.9296Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.5655 4.60611H11.6491V5.41045H15.5655V4.60611Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.564 7.8653H7.18437V8.66964H15.564V7.8653Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.564 11.2575H7.18437V12.0619H15.564V11.2575Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M15.564 14.446H7.18437V15.2504H15.564V14.446Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M13.9777 21.8926L14.9847 18.8433L25.2562 8.8936L27.2357 10.8731L17.0977 21.0102L13.9777 21.8926Z"
          stroke="#231815"
          strokeLinejoin="round"
        />
        <path
          d="M13.9777 21.8926L14.9847 18.8433L25.2562 8.8936L27.2357 10.8731L17.0977 21.0102L13.9777 21.8926Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M23.4406 10.6529L23.2309 10.8626L25.2375 12.8692L25.4472 12.6595L23.4406 10.6529Z"
          fill="black"
        />
        <path
          d="M15.5136 19.3764L16.6107 20.4735L14.9707 20.8829L15.5136 19.3764Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_268">
          <rect width="27" height="24" fill={selected ? '#609DF9' : 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function SetAnnoCreateIcon(
  props: Partial<CustomIconComponentProps> & { selected: boolean }
) {
  const { selected, ...rest } = props
  return <Icon component={() => SetAnnoCreate(selected)} {...rest} />
}
