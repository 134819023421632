import Const from 'constants/constant'
import { atom } from 'recoil'
import { OrderByType } from 'types'
import {
  BuildingFile,
  FloorDetail,
  Link,
  Point,
  SelectedFiles,
  SetAnnotation
} from 'types/building'
import { ParamQueryBuilding } from 'types/listUser'

type BuildingMode = {
  selectedMode: string
  previewMode: boolean
}

export const buildingModeState = atom<BuildingMode>({
  key: 'buildingMode',
  default: {
    selectedMode: '',
    previewMode: false
  }
})

export const buildingFilesState = atom<{
  files: BuildingFile[]
  folders: { id: number; name: string; order: number }[] | null
}>({
  key: 'buildingFiles',
  default: {
    files: [],
    folders: null
  }
})

export const floorDetailState = atom<FloorDetail | null>({
  key: 'floorDetail',
  default: null
})

export const selectedAreaState = atom<number | undefined>({
  key: 'selectedArea',
  default: undefined
})

export const isUploadingMapState = atom<{
  isOpen: boolean
  mapFile: File | null
}>({
  key: 'isUploadingMap',
  default: {
    isOpen: false,
    mapFile: null
  }
})

export const listPointState = atom<Point[]>({
  key: 'listPoint',
  default: []
})

export const listLinkState = atom<Link[]>({
  key: 'listLink',
  default: []
})

export const listChangedPointsState = atom<
  { id: string; x: number; y: number }[]
>({
  key: 'listChangedPoint',
  default: []
})

export const selectedPointDetail = atom<Point | undefined>({
  key: 'selectedPointDetailState',
  default: undefined
})

export const revalidatePointDetailState = atom<string | undefined>({
  key: 'revalidatePointDetail',
  default: undefined
})

export const revalidateFileListState = atom<number | undefined>({
  key: 'revalidateFileList',
  default: undefined
})

export const revalidateBuildingDetailState = atom<boolean>({
  key: 'revalidateBuildingDetail',
  default: false
})

export const selectedPoint = atom<string | undefined>({
  key: 'currentSelectedPoint',
  default: undefined
})

export const uploadingFiles = atom<SelectedFiles[]>({
  key: 'currentUploadFiles',
  default: []
})

export const queryParamListBuildingState = atom<ParamQueryBuilding>({
  key: 'queryParamListBuilding',
  default: {
    sort: 'updatedAt',
    sortOrder: OrderByType.descend,
    pageSize: Const.DEFAULT_PAGINATION.defaultPageSize,
    page: Const.DEFAULT_PAGINATION.current
  }
})

export const setAnnotationModeState = atom<{
  mode: string | undefined
  selectedSetAnnotation: number | undefined
  setAnnotationDetail: SetAnnotation | undefined
  annotationId: string | undefined
}>({
  key: 'setAnnotationMode',
  default: {
    mode: undefined,
    selectedSetAnnotation: undefined,
    setAnnotationDetail: undefined,
    annotationId: undefined
  }
})
