import React, { useEffect } from 'react'
import { Modal as AntModal, ModalProps } from 'antd'

type Props = {
  isEnterable?: boolean
  onEnter?: () => void
} & ModalProps

export default function Modal({ isEnterable, onEnter, ...modalProps }: Props) {
  const {
    footer = null,
    centered = true,
    closeIcon = false,
    maskClosable = false,
    transitionName = '',
    rootClassName = 'modal-custom',
    open = true,
    ...rest
  } = modalProps

  useEffect(() => {
    const handlePressEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && onEnter) {
        onEnter()
      }
    }
    if (isEnterable) {
      document.addEventListener('keypress', handlePressEnter)
    }
    return () => {
      if (isEnterable) {
        document.removeEventListener('keypress', handlePressEnter)
      }
    }
  }, [onEnter, isEnterable])

  return (
    <AntModal
      open={open}
      footer={footer}
      centered={centered}
      closeIcon={closeIcon}
      maskClosable={maskClosable}
      transitionName={transitionName}
      rootClassName={rootClassName}
      {...rest}
    />
  )
}

Modal.defaultProps = {
  isEnterable: false,
  onEnter: undefined
}
