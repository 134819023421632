import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Dropdown, notification, Typography } from 'antd'
import Const from 'constants/constant'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingFilesState,
  listPointState,
  revalidatePointDetailState,
  selectedPoint
} from 'store/buildingStore'
import useSWR from 'swr'
import { OpenModalDelete } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import MoreIcon from 'components/icons/MoreIcon'

import ModalDeletePoint from '../ModalDeletePoint'

import ModalUploadSubImages from './ModalUploadSubImages'

type Props = {
  pointId: string
  pointOrder: number
  profile: CurrentUser | null | undefined
  setIsExpanded: Dispatch<SetStateAction<boolean>>
}

export default function MenuDropDownPoint({
  pointId,
  pointOrder,
  profile,
  setIsExpanded
}: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const navigate = useNavigate()

  const [open, setOpen] = useState<OpenModalDelete>({
    pointId,
    pointOrder,
    isOpen: ''
  })

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const [, setPoints] = useRecoilState(listPointState)
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )

  const { mutate: refetchPoints } = useSWR(
    {
      url: 'v1/points',
      params: {
        buildingId: id,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )
  const { mutate: getFileList } = useSWR(
    {
      url: 'v1/buildings/files',
      params: {
        buildingId: id,
        floorId,
        category: 1
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger: deleteImageLinkWithPoint, isMutating } =
    useMutation(`v1/points/360-image`)

  const handleDeleteImage = () => {
    !isMutating &&
      deleteImageLinkWithPoint(
        {
          method: 'delete',
          data: null,
          params: {
            building_id: id,
            floor_id: floorId,
            point_id: pointId
          },
          successMessage: t('building.deleted')
        },
        {
          onSuccess: (data) => {
            if (pointId === currentSelectedPoint) {
              Krpano.UnmountPanoPreview()
              Krpano.UnmountPanoFull()
            }
            setPoints((prev) =>
              prev.map((point) =>
                point.id === pointId
                  ? {
                      ...point,
                      isSubImages: data?.data?.isSubImages || false,
                      image360: data?.data?.image360 || null
                    }
                  : point
              )
            )
            setBuildingFiles((prev) => ({
              ...prev,
              files: prev.files.map((file) =>
                file.pointId === pointId ? { ...file, pointId: null } : file
              )
            }))
            setRevalidatePointDetail(pointId)
          },
          onError: (err) => {
            if (err.response?.data.error.key === 'point_not_exists_error') {
              refetchPoints()
              getFileList().then((data) => setBuildingFiles(data.files))
              notification.error({
                message: t('translation.pointAlreadyDeleted')
              })
            } else {
              Utils.handleErrorNavigate(err, navigate)
            }
          }
        }
      )
  }

  const handleClick = (key: string) => {
    switch (key) {
      case 'handleAddSubImages':
        setOpen({ pointId, pointOrder, isOpen: key })
        break
      case 'handleDeletePoint':
        setOpen({ pointId, pointOrder, isOpen: key })
        break
      case 'handleDeleteImageLinkWithPoint':
        handleDeleteImage()
        break
      default:
        break
    }
  }

  const items = Const.POINT_DROPDOWN_MENU.filter(
    (item) => profile?.role && item.role.includes(profile?.role)
  ).map(({ label, key }) => {
    return {
      key,
      label: (
        <Typography className="text-white text-[12px] font-[700] whitespace-nowrap w-[108px]">
          {t(label)}
        </Typography>
      ),
      onClick: () => handleClick(key)
    }
  })

  return (
    <div onClick={(e: any) => e.stopPropagation()}>
      <Dropdown
        menu={{ items }}
        className="w-auto"
        placement="bottomLeft"
        trigger={['click']}
        rootClassName="dropdown-custom-delete-point w-[108px]"
      >
        <div className="h-full flex my-auto">
          <MoreIcon />
        </div>
      </Dropdown>

      {open?.isOpen === 'handleDeletePoint' && (
        <ModalDeletePoint open={open} setOpen={setOpen} />
      )}

      {open.isOpen === 'handleAddSubImages' && (
        <ModalUploadSubImages
          open={open}
          setOpen={setOpen}
          setIsExpanded={setIsExpanded}
        />
      )}
    </div>
  )
}
