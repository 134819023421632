import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import {
  buildingFilesState,
  floorDetailState,
  listLinkState,
  listPointState,
  selectedAreaState,
  selectedPointDetail
} from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { AreaDetail } from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  areaDetail: AreaDetail | undefined
  setIsOpen: Dispatch<SetStateAction<string>>
}

export default function DeleteAreaModal({ areaDetail, setIsOpen }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const navigate = useNavigate()
  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)
  const [points, setPoints] = useRecoilState(listPointState)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const [currentPov, setCurrentPov] = useRecoilState(currentPovState)
  const [links, setLinks] = useRecoilState(listLinkState)
  const [selectedArea, setSelectedArea] = useRecoilState(selectedAreaState)
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)

  const { trigger: deleteArea, isMutating } = useMutation(
    `v1/areas/${selectedArea}`
  )

  const { trigger: handleSort } = useMutation('v1/points/reorder')

  const { trigger: handleSortArea } = useMutation(`v1/floors/${floorId}/areas`)

  const { mutate } = useSWR(
    {
      url: `v1/floors/${floorId}`
    },
    {
      revalidateOnMount: false
    }
  )

  const getFloorDetail = () => mutate().then((data) => setFloorDetail(data))

  const handleClose = () => {
    setIsOpen('')
  }

  const sortArea = () => {
    if (floorDetail?.areas && floorDetail?.areas.length) {
      const areaList = [...floorDetail.areas].filter(
        (item) => item.id !== selectedArea
      )
      const reorderedAreas = areaList.map((item, index) => ({
        ...item,
        order: index + 1
      }))
      handleSortArea(
        {
          method: 'patch',
          data: {
            areaIds: reorderedAreas.map((item) => item.id)
          }
        },
        {
          onSuccess: () => {
            const newListArea = areaList.map((item, index) => ({
              ...item,
              order: index + 1
            }))
            setFloorDetail(
              (prev) =>
                prev && {
                  ...prev,
                  areas: newListArea
                }
            )
          },
          onError: (error) => {
            getFloorDetail()
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
    }
  }

  const handleDelete = () => {
    !isMutating &&
      deleteArea(
        {
          method: 'delete',
          data: null,
          successMessage: t('building.deleted')
        },
        {
          onSuccess: () => {
            const removePoints = [...points].filter(
              (point) => point.areaId === selectedArea
            )
            const newListPoints = [...points]
              .filter((point) => point.areaId !== selectedArea)
              .sort((a, b) => a.order - b.order)
            const orderedPoints = newListPoints.map((point, index) => ({
              ...point,
              order: index + 1
            }))
            const newListLinks = [...links].filter(
              (link) =>
                !removePoints.find((point) => link.id.includes(point.id))
            )
            setPoints(orderedPoints)
            if (newListPoints.length) {
              handleSort({
                data: {
                  building_id: Number(id),
                  floor_id: Number(floorId),
                  orders: orderedPoints.map((item) => ({
                    id: item.id,
                    order: item.order
                  }))
                }
              })
            }
            setLinks(newListLinks)
            setSelectedArea(undefined)
            setBuildingFiles((prev) => ({
              ...prev,
              files: prev.files.map((file) =>
                file.pointId &&
                !newListPoints.find((point) => point.id === file.pointId)
                  ? { ...file, pointId: null }
                  : file
              )
            }))
            if (pointDetail) setPointDetail(undefined)
            if (currentPov) setCurrentPov(null)
            sortArea()
            handleClose()
          },
          onError: (error) => {
            setSelectedArea(undefined)
            getFloorDetail()
            handleClose()
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  return (
    <Modal
      onCancel={handleClose}
      width={580}
      isEnterable
      onEnter={handleDelete}
    >
      <Title title={t('building.deleteArea')} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.deleteConfirmation')}
        </Typography>

        <div className="bg-[#F9FBFF] rounded-[10px] py-[18px] px-5">
          {areaDetail?.name}
        </div>

        <div className="flex gap-10 justify-center">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleDelete}
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
