import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form, notification, Typography, Upload, UploadProps } from 'antd'
import clsx from 'clsx'
import useUpload from 'hooks/useUpload'
import { useRecoilState } from 'recoil'
import { uploadingFiles } from 'store/buildingStore'
import {
  FileMagicNumber,
  Folders,
  SelectedFiles,
  UploadingFiles,
  UploadStatus
} from 'types/building'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import UploadStatusIcon from 'components/atoms/UploadStatusIcon'
import DeleteIcon from 'components/icons/DeleteIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

const { Dragger } = Upload

type Props = {
  documentBreadcrumb: { doc: Folders; subDoc?: Folders } | undefined
  handleSwitchMenu: () => void
  selectedFolder: { doc: Folders; subDoc?: Folders } | undefined
  folderId: number | undefined
}

export default function UploadFileMenu({
  documentBreadcrumb,
  handleSwitchMenu,
  selectedFolder,
  folderId
}: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const typeRrrorRef = useRef<boolean>(false)
  const sizeErrorRef = useRef<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useRecoilState(uploadingFiles)

  const { id } = useParams()
  const category =
    documentBreadcrumb && documentBreadcrumb.doc.isFileHolder
      ? documentBreadcrumb.doc.id
      : documentBreadcrumb?.subDoc?.id || 0

  const { uploadFile, reUpload, cancelUpload, removeSuccessFile } = useUpload({
    buildingId: Number(id),
    category,
    folderId
  })

  const dataSource: SelectedFiles[] = useMemo(
    () =>
      selectedFiles.filter(
        (item) => item.buildingId === id && item.category === category
      ),
    [selectedFiles, category, id]
  )

  const handleOpenDialog = () => {
    const fileUpload = document.getElementById('building-file-upload')
    fileUpload && fileUpload.click()
  }

  const typesImage = ['.jpg', '.jpeg', '.png']
  const typesVideo = ['mov', 'mp4']
  const fullTypes = ['.jpg', '.jpeg', '.png', '.pdf', '.mp4', '.mov']

  const isTypeInclude = (name: string, types: string[]) => {
    return Boolean(types.find((type) => name.toLowerCase().includes(type)))
  }

  const validateArray = (filename: string) => {
    if (isTypeInclude(filename, typesImage)) return FileMagicNumber.IMAGE
    if (isTypeInclude(filename, typesVideo)) return FileMagicNumber.VIDEO
    return FileMagicNumber.PDF
  }

  const isTypeError = (filename: string) => {
    return (
      (selectedFolder?.doc.id === 1 && !isTypeInclude(filename, typesImage)) ||
      (selectedFolder?.doc.id !== 1 && !isTypeInclude(filename, fullTypes))
    )
  }

  const props: UploadProps = {
    name: 'building-file-upload',
    multiple: true,
    showUploadList: false,
    beforeUpload: async (file, fileList) => {
      if (fileList[fileList.length - 1].uid === file.uid) {
        const files: UploadingFiles[] = []
        for (let i = 0; i < fileList.length; i += 1) {
          let skip = false
          if (isTypeError(fileList[i].name) && !skip) {
            typeRrrorRef.current = true
            skip = true
          }
          if (
            isTypeInclude(fileList[i].name, typesImage) &&
            fileList[i].size / (1024 * 1024) > 50 &&
            !skip
          ) {
            sizeErrorRef.current = true
            skip = true
          }
          if (fileList[i].size / (1024 * 1024) > 1000 && !skip) {
            sizeErrorRef.current = true
            skip = true
          }
          if (!skip) {
            // eslint-disable-next-line no-await-in-loop
            await Utils.getFileMagicNumber(
              fileList[i],
              validateArray(fileList[i].name)
            )
              .then(() => {
                files.push({
                  file: fileList[i],
                  uploadStatus: UploadStatus.LOADING,
                  abortSignal: new AbortController()
                })
              })
              .catch(() => {
                notification.error({
                  key: 'brokenFileMessage',
                  message: t('translation.brokenFileMessage')
                })
              })
          }
        }
        files.length &&
          setSelectedFiles((prev) => [
            ...prev,
            {
              id: selectedFiles.length + 1,
              buildingId: `${id}`,
              category,
              files
            }
          ])
      }
      return false
    },
    fileList: []
  }

  const normFile = ({ file, fileList }: any) => {
    if (fileList[fileList.length - 1].uid === file.uid) {
      if (typeRrrorRef.current) {
        notification.error({
          message:
            selectedFolder?.doc.id === 1
              ? t('common.validationError.building360ImageValidate')
              : t('common.validationError.buildingFileNotAllowed')
        })
        typeRrrorRef.current = false
      }
      if (sizeErrorRef.current) {
        const error = isTypeInclude(file.name, typesImage)
          ? t('common.validationError.fileTooLarge')
          : t('common.validationError.buildingFileTooLarge')
        notification.error({
          message: error
        })
        sizeErrorRef.current = false
      }

      uploadFile()
      form.resetFields()
    }
  }
  useEffect(() => {
    removeSuccessFile()
    return () => {
      removeSuccessFile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={clsx(
          'grid  p-[10px] overflow-auto',
          {
            'grid-rows-[auto_1fr]': !dataSource.length
          },
          {
            'grid-rows-[auto_auto_1fr]': dataSource.length
          }
        )}
      >
        <div
          className="flex gap-3 pb-[10px] cursor-pointer"
          onClick={handleSwitchMenu}
        >
          <LeftArrowIcon />
          <Typography className="text-white font-[700] text-[14px] my-auto overflow-hidden">
            {documentBreadcrumb?.doc?.label}
            {documentBreadcrumb?.subDoc ? (
              <>&nbsp;&gt;&nbsp;{documentBreadcrumb.subDoc.label}</>
            ) : (
              ''
            )}
          </Typography>
        </div>

        <Form form={form}>
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Dragger
              id="building-file-upload"
              {...props}
              openFileDialogOnClick={false}
            >
              <div
                className={clsx(
                  'flex flex-col gap-3 items-center justify-center',
                  { 'h-[118px]': dataSource.length }
                )}
              >
                <Typography className="w-full text-left text-white font-medium text-[10px] px-[10px] leading-[16px]">
                  {t('building.imageUploadPlaceholder')}
                </Typography>
                <Button
                  onClick={handleOpenDialog}
                  type="primary"
                  className="cursor-pointer"
                  sizing="h-[40px] w-[160px]"
                >
                  {t('building.selectFile')}
                </Button>
              </div>
            </Dragger>
          </Form.Item>
        </Form>

        {dataSource.length ? (
          <div className="overflow-y-auto">
            {dataSource.map((file) =>
              file.files.map((item) => (
                <div
                  key={item?.file.uid}
                  className="grid grid-cols-[1fr_auto] gap-1 border-0 border-b border-solid border-[#1C314D] py-2 px-3"
                >
                  <div className="grid">
                    <Typography className="text-[13px] leading-[19.5px] text-white">
                      {item?.file.name}
                    </Typography>
                    {item.uploadStatus === UploadStatus.FAIL && (
                      <Typography className="text-[13px] leading-[19.5px] text-[#EC3C3C]">
                        {t('building.uploadFail')}
                      </Typography>
                    )}
                  </div>

                  <div className="flex gap-1 items-center">
                    <div
                      className={clsx('flex items-center', {
                        'cursor-pointer':
                          item.uploadStatus === UploadStatus.FAIL
                      })}
                      onClick={() =>
                        item.uploadStatus === UploadStatus.FAIL &&
                        reUpload(file.id, item.file)
                      }
                    >
                      <UploadStatusIcon status={item.uploadStatus} />
                    </div>

                    <div
                      className="cursor-pointer"
                      onClick={cancelUpload(file.id, item)}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : null}
      </div>
      <div className="flex h-[3.75rem] border-0 border-t border-solid border-[#1C314D]">
        <Button
          type="primary"
          className="m-auto"
          sizing="h-[40px] w-[160px]"
          onClick={handleSwitchMenu}
        >
          {t('building.buttonCancel')}
        </Button>
      </div>
    </>
  )
}
