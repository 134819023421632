import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { Link, useSearchParams } from 'react-router-dom'
import { Pagination, Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import usePermissions from 'hooks/usePermissions'
import { ListOrganizations } from 'models/Organization'
import useSWR from 'swr'
import { OrderByType } from 'types'

import Button from 'components/atoms/Button'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearch from './components/FormSearch'

export default function OrganizationList() {
  const { t } = useTranslation()
  const { isCreate } = usePermissions('organization')

  const [queries, setQueries] = useSearchParams()
  const page = queries.get('page')

  const {
    data: organizations,
    isValidating,
    mutate
  } = useSWR<ListOrganizations>({
    url: `/v1/organizations`,
    params: {
      ...Object.fromEntries(queries.entries()),
      page: page || 1,
      pageSize: 10,
      fields: 'buildingCount'
    }
  })

  const hiddenPagination = useMemo(
    () =>
      Boolean(
        organizations?.pagination.total &&
          organizations.pagination.total /
            Const.DEFAULT_PAGINATION.defaultPageSize >
            1
      ),
    [organizations?.pagination.total]
  )

  const dataSource = useMemo(
    () =>
      organizations?.edges.map((item) => ({
        ...item,
        name: (
          <div className="grid grid-cols-1">
            <Typography.Paragraph
              className="!mb-0"
              ellipsis={{
                rows: 2,
                tooltip: item.name
              }}
            >
              {item.name}
            </Typography.Paragraph>
          </div>
        )
      })) || [],
    [organizations?.edges]
  )

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      queries.set('sort', sorter ? field : 'id')
      queries.set(
        'sortOrder',
        sorter
          ? OrderByType[sorter as keyof typeof OrderByType]
          : OrderByType.descend
      )
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const handleSearch = (value: any) => {
    Object.keys(value).forEach((key) => {
      if (queries.get(key) && !value[key]) {
        queries.delete(key)
      }
      value[key] && queries.set(key, value[key])
    })
    queries.set('page', '1')
    setQueries(queries)
  }

  const onPageChange = useCallback(
    (page: number) => {
      queries.set('page', `${page}`)
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="text-[#3163b7]" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="text-[#3163b7]" />
        </div>
      )
    }
    return originalElement
  }

  return (
    <>
      <div className="flex justify-between pb-5 items-center">
        <div className="font-bold text-xl">{t('organization.title')}</div>
        {isCreate && (
          <Link to="register" className="no-underline">
            <Button type="primary" sizing="w-[160px] h-[40px]">
              {t('organization.buttonAdd')}
            </Button>
          </Link>
        )}
      </div>

      <div className="px-10 py-5 bg-white rounded-lg shadow-sm mb-5 lg:px-[30px]">
        <FormSearch handleSearch={handleSearch} />
      </div>
      <ExtraTable
        isLoading={isValidating && !organizations}
        columns={TableColumn.COLUMN_ORGANIZATION}
        data={dataSource}
        onChange={onTableChange}
        total={organizations?.pagination.total}
        refetch={mutate}
      />

      {hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={+(page || 1)}
            pageSize={10}
            total={organizations?.pagination.total || 0}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}
    </>
  )
}
