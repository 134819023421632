import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function SetAnnoSearch(selected: boolean) {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_243)">
        <path
          d="M21.7912 21.5987H10.1406C9.37019 21.5987 8.7433 20.9718 8.7433 20.2014V19.4681H9.53184V20.2014C9.53184 20.5365 9.80468 20.8101 10.1406 20.8101H21.7912C22.1264 20.8101 22.4 20.5373 22.4 20.2014V4.62303C22.4 4.27055 22.113 3.98273 21.7597 3.98273H20.5674V3.1942H21.7597C22.5475 3.1942 23.1885 3.83528 23.1885 4.62303V20.2014C23.1885 20.9718 22.5617 21.5987 21.7912 21.5987Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M18.0164 0H11.0867C10.627 0 10.1949 0.17821 9.86844 0.503087L5.45656 4.90629C5.16638 5.19489 5 5.59389 5 6.00314V17.0072C5 17.7776 5.62689 18.4045 6.39729 18.4045H18.0479C18.8183 18.4045 19.4452 17.7776 19.4452 17.0072V1.42883C19.4452 0.641082 18.8042 0 18.0164 0ZM10.4244 1.06137C10.4362 1.04954 10.4488 1.03929 10.4614 1.02825V5.15073C10.4614 5.3526 10.2974 5.51661 10.0955 5.51661H5.96596C5.98094 5.49848 5.99671 5.48113 6.01327 5.46457L10.4244 1.06137ZM18.6559 17.0072C18.6559 17.3423 18.3831 17.6159 18.0472 17.6159H6.39729C6.06216 17.6159 5.78854 17.3431 5.78854 17.0072V6.30515H10.0955C10.7319 6.30515 11.25 5.78708 11.25 5.15073V0.788538H18.0164C18.3689 0.788538 18.6567 1.07557 18.6567 1.42883V17.0072H18.6559Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M16.3394 4.51674H12.5V5.30528H16.3394V4.51674Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M16.3397 7.71094H8.12388V8.49948H16.3397V7.71094Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M16.3397 11.0324H8.12388V11.8209H16.3397V11.0324Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M16.3397 14.1629H8.12388V14.9515H16.3397V14.1629Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M22.9049 15.131C22.9049 17.2153 21.2153 18.9049 19.131 18.9049C17.0468 18.9049 15.3571 17.2153 15.3571 15.131C15.3571 13.0468 17.0468 11.3571 19.131 11.3571C21.2153 11.3571 22.9049 13.0468 22.9049 15.131Z"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M23.7857 15.1429C23.7857 17.707 21.707 19.7857 19.1429 19.7857C16.5787 19.7857 14.5 17.707 14.5 15.1429C14.5 12.5787 16.5787 10.5 19.1429 10.5C21.707 10.5 23.7857 12.5787 23.7857 15.1429Z"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M23.2405 18.572L22.5714 19.2411L26.2983 22.9679L26.9674 22.2988L23.2405 18.572Z"
          fill={selected ? '#609DF9' : 'white'}
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M23.2405 18.572L22.5714 19.2411L26.2983 22.9679L26.9674 22.2988L23.2405 18.572Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <path
          d="M26.7233 23.7126C26.4703 23.7126 26.2164 23.6162 26.0237 23.4226L23.7167 21.1158C23.3303 20.7294 23.3303 20.103 23.7167 19.7167C24.103 19.3303 24.7295 19.3303 25.1158 19.7167L27.4228 22.0235C27.8092 22.4099 27.8092 23.0363 27.4228 23.4226C27.2292 23.6162 26.9762 23.7126 26.7233 23.7126Z"
          fill={selected ? '#609DF9' : 'white'}
        />
        <g filter="url(#filter0_d_1_243)">
          <path
            d="M19.1293 19.4032C21.4897 19.4032 23.4032 17.4897 23.4032 15.1293C23.4032 12.7689 21.4897 10.8555 19.1293 10.8555C16.7689 10.8555 14.8555 12.7689 14.8555 15.1293C14.8555 17.4897 16.7689 19.4032 19.1293 19.4032Z"
            stroke={selected ? '#609DF9' : 'white'}
            strokeMiterlimit="10"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1_243"
          x="10.3555"
          y="10.3555"
          width="17.5478"
          height="17.5478"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_243"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_243"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1_243">
          <rect width="28" height="24" fill={selected ? '#609DF9' : 'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default function SetAnnoSearchIcon(
  props: Partial<CustomIconComponentProps> & { selected: boolean }
) {
  const { selected, ...rest } = props
  return (
    <Icon
      className="flex w-7 h-6 object-contain"
      component={() => SetAnnoSearch(selected)}
      {...rest}
    />
  )
}
