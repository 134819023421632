import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { buildingFilesState } from 'store/buildingStore'

import RightArrowIcon from 'components/icons/RightArrowIcon'

type Props = {
  categoryId: number | undefined
  setSelectedChildrenFolder: Dispatch<SetStateAction<number | undefined>>
}

export default function ChildrenFolderList({
  categoryId,
  setSelectedChildrenFolder
}: Props) {
  const { id } = useParams()

  const [buildingFiles, setBuildingFiles] = useRecoilState(buildingFilesState)

  const { trigger, isMutating } = useMutation(
    `/v1/buildings/${id}/folders/reorder`
  )

  const sortedFolders = useMemo(
    () => [...(buildingFiles?.folders || [])].sort((a, b) => a.order - b.order),
    [buildingFiles?.folders]
  )

  const handleOnDragEnd = useCallback(
    (result: any) => {
      if (sortedFolders) {
        if (!result.destination) return

        const items = [...sortedFolders]
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        setBuildingFiles((prev) => ({
          ...prev,
          folders: items.map((item, index) => ({ ...item, order: index + 1 }))
        }))
        !isMutating &&
          trigger({
            method: 'patch',
            data: {
              categoryId,
              folderIds: items?.map((item) => item.id)
            }
          })
      }
    },
    [categoryId, isMutating, setBuildingFiles, sortedFolders, trigger]
  )

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="childrenFolder">
        {(provided: any) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {sortedFolders?.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
              >
                {(dragProvided: any) => (
                  <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    className="grid grid-cols-[1fr_auto] gap-2 items-center bg-[#162841] px-[10px] py-3 cursor-pointer text-ellipsis"
                    onClick={() => setSelectedChildrenFolder(item.id)}
                  >
                    <Typography.Text
                      className="text-white"
                      ellipsis={{
                        tooltip: item.name
                      }}
                    >
                      {item.name}
                    </Typography.Text>
                    <RightArrowIcon />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
