import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { Empty, Form, Input, Typography } from 'antd'
import Const from 'constants/constant'
import useSWR from 'swr'
import { BuildingChildrenFolder, ListBuildingFiles } from 'types/building'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import ViewFile360Icon from 'components/icons/360ViewFileIcon'
import ChildrenFolderIcon from 'components/icons/ChildrenFolderIcon'

import ViewerFilePreview from '../buildingDetailViewMode/components/ViewerFilePreview'
import PreviewFile from '../panoramas/components/PreviewFile'

import FolderIcon from './FolderIcon'
import ModalListRelatedPoints from './ModalListRelatedPoints'

export default function ListFile() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [selectedFile, setSelectedFile] = useState<number>()
  const [choosenChildrenFolder, setChoosenChildrenFolder] = useState<string>()

  const [searchParams, setSearchParams] = useSearchParams()
  const folder = searchParams.get('folder')
  const children = searchParams.get('children')
  const selectedChildrenFolder = searchParams.get('children_folder')
  const previewIndex = searchParams.get('preview_idx')
  const keyword = searchParams.get('keyword')

  const selectedFolder = useMemo(
    () => Const.BUILDING_FOLDER.find((item) => `${item.id}` === folder),
    [folder]
  )

  const selectedSubFolder = useMemo(
    () => selectedFolder?.children?.find((item) => `${item.id}` === children),
    [children, selectedFolder?.children]
  )

  const isShowingFileLevel =
    selectedFolder && (selectedFolder.isFileHolder || selectedSubFolder)

  const { data: buildingFiles, isValidating } = useSWR<ListBuildingFiles>(
    isShowingFileLevel && !selectedChildrenFolder
      ? {
          url: '/v1/buildings/files',
          params: {
            building_id: id,
            category: selectedFolder?.isFileHolder
              ? selectedFolder.id
              : selectedSubFolder?.id,
            keyword
          }
        }
      : null
  )

  const sortedFiles = useMemo(
    () =>
      [...(buildingFiles?.files.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [buildingFiles?.files.files]
  )

  const sortedFolder = useMemo(
    () =>
      [...(buildingFiles?.files.folders || [])].sort(
        (a, b) => a.order - b.order
      ),
    [buildingFiles?.files.folders]
  )

  const { data: childrenFolder, isValidating: isValidatingChildrenFolder } =
    useSWR<BuildingChildrenFolder>(
      selectedChildrenFolder
        ? {
            url: `v1/buildings/${id}/folders/${selectedChildrenFolder}`,
            params: {
              keyword
            }
          }
        : null
    )

  const sortedChildrenFolderFiles = useMemo(
    () =>
      [...(childrenFolder?.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [childrenFolder?.files]
  )

  const isFileListEmpty = useMemo(() => {
    if (
      selectedChildrenFolder &&
      !isValidatingChildrenFolder &&
      !sortedChildrenFolderFiles.length
    ) {
      return true
    }
    if (
      !isValidating &&
      !selectedChildrenFolder &&
      !sortedFolder.length &&
      !sortedFiles.length
    ) {
      return true
    }
    return false
  }, [
    isValidating,
    isValidatingChildrenFolder,
    selectedChildrenFolder,
    sortedChildrenFolderFiles.length,
    sortedFiles.length,
    sortedFolder.length
  ])

  const previewFile = useMemo(() => {
    if (previewIndex) {
      return selectedChildrenFolder
        ? sortedChildrenFolderFiles[+previewIndex]
        : sortedFiles[+previewIndex]
    }
    return undefined
  }, [
    previewIndex,
    selectedChildrenFolder,
    sortedChildrenFolderFiles,
    sortedFiles
  ])

  const previewLength = useMemo(
    () =>
      selectedChildrenFolder
        ? sortedChildrenFolderFiles.length
        : sortedFiles.length,
    [
      selectedChildrenFolder,
      sortedChildrenFolderFiles.length,
      sortedFiles.length
    ]
  )

  const handleSelectChildrenFolder = (id: number, name: string) => {
    searchParams.set('children_folder', `${id}`)
    keyword && searchParams.delete('keyword')
    setSearchParams(searchParams)
    setChoosenChildrenFolder(name)
  }

  const handleSearch = (values: any) => {
    searchParams.set('keyword', values.keyword)
    setSearchParams(searchParams, { replace: true })
  }

  const handleBack = () => {
    if (selectedChildrenFolder) {
      setChoosenChildrenFolder(undefined)
      searchParams.delete('children_folder')
    } else if (selectedSubFolder) {
      searchParams.delete('children')
    } else {
      searchParams.delete('folder')
    }
    keyword && searchParams.delete('keyword')
    setSearchParams(searchParams)
  }

  const handleSelectPreview = (index: number) => {
    searchParams.set('preview_idx', `${index}`)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    form.setFieldValue('keyword', keyword)
  }, [form, keyword])

  if (!isShowingFileLevel) return null

  return (
    <>
      <Form form={form} preserve={false} onFinish={handleSearch}>
        <div className="flex items-center justify-between rounded-[10px] bg-white pl-10 pr-5 py-5">
          <div className="flex items-center gap-[10px]">
            <Typography className="my-auto text-[14px] leading-[21px] text-[#021120] font-bold">
              キーワード
            </Typography>
            <Form.Item
              rules={[
                {
                  validator: Validators.searchFileValidator(t, false, true)
                }
              ]}
              name="keyword"
              className="mb-0 thin-error-message"
            >
              <Input className="w-[180px] h-[38px] rounded-md" />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit">
            検索
          </Button>
        </div>
      </Form>
      <div className="grid grid-rows-[auto_1fr] overflow-auto">
        <div className="px-5 bg-[#3163B7]">
          <div
            className="flex gap-4 py-1 items-center mr-auto cursor-pointer"
            onClick={handleBack}
          >
            <FolderIcon type={selectedFolder.id} size="small" />
            <Typography className="text-white font-medium">
              {selectedFolder?.label}
              {!!selectedSubFolder && (
                <>&nbsp;&gt;&nbsp;{selectedSubFolder.label}</>
              )}
              {!!selectedChildrenFolder && (
                <>&nbsp;&gt;&nbsp;{choosenChildrenFolder}</>
              )}
            </Typography>
          </div>
        </div>
        <div className="overflow-auto bg-white">
          {(isValidating || isValidatingChildrenFolder) && <Spinner />}
          {isFileListEmpty && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('building.fileNotFound')}
            />
          )}
          {!isValidatingChildrenFolder && selectedChildrenFolder
            ? sortedChildrenFolderFiles.map((file, index) => (
                <div
                  key={file.id}
                  className="flex items-center gap-5 justify-between min-h-[80px] p-[10px] border-0 border-b border-solid border-[#EEEFF2]"
                >
                  <div
                    className="flex items-center gap-5 cursor-pointer"
                    onClick={() => handleSelectPreview(index)}
                  >
                    <div className="flex-shrink-0">
                      <PreviewFile
                        file={file}
                        size={
                          file.extension.toLowerCase() === 'pdf'
                            ? 'default'
                            : 'large'
                        }
                      />
                    </div>
                    <Typography className="font-medium text-[#021120]">
                      {file.name}
                    </Typography>
                  </div>
                  {file.isRelated && (
                    <Button
                      className="border-[1px] border-solid border-[#3163B7] text-[#3163B7] gap-[3px] py-[7px] px-[3px] bg-white flex justify-center items-center rounded-[4px] hover:!cursor-pointer hover:!text-[#3163B7] hover:!bg-white hover:!opacity-100 mr-[28px]"
                      onClick={() => setSelectedFile(file.id)}
                      sizing="w-[160px] h-[40px]"
                    >
                      <ViewFile360Icon />
                      {t('building.360ViewFile')}
                    </Button>
                  )}
                </div>
              ))
            : null}
          {!isValidating && !selectedChildrenFolder ? (
            <>
              {sortedFolder.map((item) => (
                <div
                  key={item.id}
                  className="min-h-[80px] flex gap-5 items-center p-[10px] border-0 border-b border-solid border-[#EEEFF2] cursor-pointer"
                  onClick={() => handleSelectChildrenFolder(item.id, item.name)}
                >
                  <ChildrenFolderIcon />
                  <div className="text-base text-[#021120]">{item.name}</div>
                </div>
              ))}
              {sortedFiles.map((file, index) => (
                <div
                  key={file.id}
                  className="flex items-center gap-5 justify-between min-h-[80px] p-[10px] border-0 border-b border-solid border-[#EEEFF2]"
                >
                  <div
                    className="flex items-center gap-5 cursor-pointer"
                    onClick={() => handleSelectPreview(index)}
                  >
                    <div className="flex-shrink-0">
                      <PreviewFile
                        file={file}
                        size={
                          file.extension.toLowerCase() === 'pdf'
                            ? 'default'
                            : 'large'
                        }
                      />
                    </div>
                    <Typography className="font-medium text-[#021120]">
                      {file.name}
                    </Typography>
                  </div>
                  {file.isRelated && (
                    <Button
                      className="border-[1px] border-solid border-[#3163B7] text-[#3163B7] gap-[3px] py-[7px] px-[3px] bg-white flex justify-center items-center rounded-[4px] hover:!cursor-pointer hover:!text-[#3163B7] hover:!bg-white hover:!opacity-100 mr-[28px]"
                      onClick={() => setSelectedFile(file.id)}
                      sizing="w-[160px] h-[40px]"
                    >
                      <ViewFile360Icon />
                      {t('building.360ViewFile')}
                    </Button>
                  )}
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>

      {buildingFiles && previewFile ? (
        <ViewerFilePreview
          fileIndex={undefined}
          setPreviewFileIndex={undefined}
          file={previewFile}
          maxLength={previewLength}
        />
      ) : null}

      <ModalListRelatedPoints
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </>
  )
}
