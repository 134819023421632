import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import { buildingFilesState } from 'store/buildingStore'
import useSWR from 'swr'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'

type Props = {
  selectedFile: { id: number; name: string }
  handleToggle: (key: { id: number; name: string } | undefined) => void

  handleSetRevalidateByCategory: () => void
}

export default function ModalDelete360Image({
  selectedFile,
  handleToggle,
  handleSetRevalidateByCategory
}: Props) {
  const { id, floorId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)

  const { mutate: refetchPoints } = useSWR(
    {
      url: 'v1/points',
      params: {
        buildingId: id,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger, isMutating } = useMutation(
    `v1/buildings/files/${selectedFile.id}`
  )

  const handleClose = () => {
    handleToggle(undefined)
  }

  const handleDeletedFile = () => {
    !isMutating &&
      trigger(
        {
          method: 'delete',
          data: null,
          successMessage: 'organization.successDeletedNotification'
        },
        {
          onSuccess: () => {
            setBuildingFiles((prev) => ({
              ...prev,
              files: prev.files.filter((item) => item.id !== selectedFile.id)
            }))
            handleClose()
          },
          onError: (err) => {
            if (
              err.response.data.error.key === 'delete_file_related_point_error'
            ) {
              refetchPoints()
              handleSetRevalidateByCategory()
              handleClose()
              notification.error({
                message: t('translation.imageIsUsed')
              })
            } else {
              Utils.handleErrorNavigate(err, navigate)
            }
          }
        }
      )
  }

  return (
    <Modal open width={480} isEnterable onEnter={handleDeletedFile}>
      <div className="flex flex-col items-center gap-10">
        <div className="flex flex-col items-center gap-[10px]">
          <div className="text-xl text-[#3163B7] font-bold">
            {t('translation.deleteImage')}
          </div>
          <div className="w-10 h-[1px] bg-[#3163B7]" />
        </div>
        <div className="text-sm">{t('translation.deleteImageGuide')}</div>
        <div className="flex px-3 w-[360px] h-[60px] bg-[#F9FBFF] rounded-[10px] truncate">
          <Typography.Text
            className="m-auto"
            ellipsis={{
              tooltip: selectedFile.name
            }}
          >
            {selectedFile.name}
          </Typography.Text>
        </div>
        <div className="flex gap-10">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('translation.cancel')}
          </Button>
          <Button
            className="bg-[#B80808]"
            sizing="w-[140px] h-[50px]"
            onClick={handleDeletedFile}
          >
            {t('translation.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
