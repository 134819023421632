import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { DatePicker, Form, Input, Select, Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import { Branch } from 'models/Branch'
import { ListOrgForListBuilding } from 'models/Building'
import { CurrentUser, Role } from 'models/User'
import useSWR from 'swr'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import CalendarIcon from 'components/icons/Calendar'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  handleSearch: (value: SearchUser) => void
}

export default function FormSearch({ handleSearch }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const constructionPeriodStart = Form.useWatch('start_date', form)
  const constructionPeriodEnd = Form.useWatch('end_date', form)

  const [searchParams, setSearchParams] = useSearchParams()

  const isSearched = Boolean(searchParams.toString())

  const formatedSearchParams = useMemo(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      organization_id,
      status,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      branch_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      start_date,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      end_date,
      ...rest
    } = Object.fromEntries(searchParams.entries())
    return {
      ...rest,
      organization_id: organization_id ? +organization_id : null,
      status: status ? +status : null,
      branch_id: branch_id ? +branch_id : null,
      start_date: start_date ? dayjs(start_date) : null,
      end_date: end_date ? dayjs(end_date) : null
    }
  }, [searchParams])

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })

  const { data: organizations } = useSWR<ListOrgForListBuilding>({
    url: `/v1/organizations-have-building`
  })

  const { data: branch } = useSWR(
    profile?.role === Role.SA
      ? {
          url: `/v1/branches/list-for-building`
        }
      : null
  )

  const listBranch = useMemo(
    () =>
      branch
        ? branch.map((item: Branch) => ({
            value: item.id,
            label: <span className="text-[#021120] text-sm">{item.name}</span>
          }))
        : [],
    [branch]
  )

  const disabledStartDate = (currentDate: any) => {
    return (
      constructionPeriodEnd &&
      dayjs(currentDate).format('YYYY-MM-DD') >=
        dayjs(constructionPeriodEnd).format('YYYY-MM-DD')
    )
  }

  const disabledEndDate = (currentDate: any) => {
    return (
      constructionPeriodStart &&
      dayjs(currentDate).format('YYYY-MM-DD') <=
        dayjs(constructionPeriodStart).format('YYYY-MM-DD')
    )
  }

  const onSearch = (values: any) => {
    const startDate = values.start_date
      ? dayjs(values.start_date).format('YYYY-MM-DD')
      : undefined
    const endDate = values.end_date
      ? dayjs(values.end_date).format('YYYY-MM-DD')
      : undefined

    handleSearch(
      Utils.trimObjectValues({
        ...values,
        start_date: startDate,
        end_date: endDate
      })
    )
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  const handleResetSearch = () => {
    if (isSearched) {
      setSearchParams({})
      form.resetFields()
    }
  }

  useEffect(() => {
    if (organizations && branch) {
      form.setFieldsValue(formatedSearchParams)
    }
  }, [branch, form, formatedSearchParams, organizations])

  return (
    <Form
      layout="horizontal"
      className="font-medium w-full"
      onFinish={onSearch}
      form={form}
      colon={false}
    >
      <div className="flex flex-wrap gap-5">
        <Form.Item
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('user.keyword')}
            </Typography>
          }
          name="keyword"
          className="flex items-center mb-0 label-custom-select thin-placeholder thin-error-message"
          rules={[{ validator: Validators.keywordValidator(t) }]}
        >
          <Input className="min-w-[180px] max-w-[180px] h-[38px] text-sm" />
        </Form.Item>
        <Form.Item
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('building.organizationNameSearch')}
            </Typography>
          }
          name="organization_id"
          className="flex items-center mb-0 label-custom-select thin-placeholder"
        >
          <Select
            placeholder={t('building.placeholder')}
            className="min-w-[180px] max-w-[180px] h-[38px]"
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
            options={[
              { value: '', label: null },
              ...(organizations?.edges.map((org) => ({
                value: org.id,
                label: <span className="text-sm">{org.name}</span>
              })) || [])
            ]}
          />
        </Form.Item>
        <Form.Item
          className="flex items-center mb-0 label-custom-select thin-placeholder"
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('building.status')}
            </Typography>
          }
          name="status"
        >
          <Select
            placeholder={t('building.placeholder')}
            className="min-w-[180px] max-w-[180px] h-[38px]"
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
            options={[
              { value: '', label: null },
              ...Const.STATUS_BUILDING.map((status) => ({
                value: status,
                label: (
                  <span className="text-sm">{t(`building.${status}`)}</span>
                )
              }))
            ]}
          />
        </Form.Item>

        {profile?.role === Role.SA && (
          <>
            <Form.Item
              className="flex items-center mb-0 label-custom-select thin-placeholder"
              label={
                <Typography className="text-[14px] leading-[21px] text-[#021120]">
                  {t('building.branchNameInfo')}
                </Typography>
              }
              name="branch_id"
            >
              <Select
                placeholder={t('building.placeholder')}
                className="min-w-[180px] max-w-[180px] h-[38px]"
                suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
                options={[
                  { value: '', label: null },
                  ...listBranch.map((item: any) => ({
                    value: item.value,
                    label: item.label
                  }))
                ]}
              />
            </Form.Item>
            <div className="mx-auto flex items-center gap-3">
              <Form.Item
                className="flex items-center mb-0 label-custom-select thin-placeholder"
                label={
                  <Typography className="text-[14px] leading-[21px] text-[#021120]">
                    {t('building.constructionStart')}
                  </Typography>
                }
                name="start_date"
              >
                <DatePicker
                  placeholder=""
                  className="h-[38px] text-sm w-[160px]"
                  suffixIcon={<CalendarIcon />}
                  format="YYYY年MM月DD日"
                  disabledDate={disabledStartDate}
                />
              </Form.Item>
              〜
              <Form.Item
                className="flex items-center mb-0 label-custom-select thin-placeholder"
                label={
                  <Typography className="text-[14px] leading-[21px] text-[#021120]">
                    {t('building.constructionEnd')}
                  </Typography>
                }
                name="end_date"
              >
                <DatePicker
                  placeholder=""
                  className="h-[38px] text-sm w-[160px]"
                  suffixIcon={<CalendarIcon />}
                  format="YYYY年MM月DD日"
                  disabledDate={disabledEndDate}
                />
              </Form.Item>
            </div>
          </>
        )}

        <div className="flex gap-9 ml-auto">
          <Button type="primary" sizing="w-[160px] h-[40px]" htmlType="submit">
            {t('user.buttonSearch')}
          </Button>

          <Button
            sizing="w-[160px] h-[40px]"
            type="primary"
            className={!isSearched ? 'opacity-50' : ''}
            onClick={handleResetSearch}
          >
            {t('translation.resetSearch')}
          </Button>
        </div>
      </div>
    </Form>
  )
}
