import { PropsWithChildren } from 'react'

import FooterTransparent from 'components/organisms/Footer/FooterTransparent'
import HeaderTransparent from 'components/organisms/Header/HeaderTransparent'

export default function DefaultLayout({ children }: PropsWithChildren) {
  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto]">
      <HeaderTransparent />

      <div className="grow h-full">{children}</div>
      <FooterTransparent />
    </div>
  )
}
