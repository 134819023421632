import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button as AntButton } from 'antd'
import { clsx } from 'clsx'

import UserInformation from './components/UserInformation'
import BuildingList from './UserBuildingList'

export default function DetailUser() {
  const { t } = useTranslation()
  const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const handleChangeTab = (key: string) => () => {
    setSearchParams(
      {
        tab: key
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (!tab) {
      searchParams.set('tab', 'information')
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setSearchParams, tab])

  return (
    <div>
      <div className="font-bold text-xl mb-5">{t('user.titleUpdated')}</div>

      <div className="w-full flex justify-start gap-1">
        <AntButton
          type={tab === 'information' ? 'primary' : 'default'}
          onClick={handleChangeTab('information')}
          className={clsx('w-36 h-12 !rounded-none !font-bold !leading-4', {
            'bg-white border border-solid border-[#3163B7] text-[#3163B7] hover:!text-[#3136B7]':
              tab !== 'information'
          })}
        >
          {t('user.information')}
        </AntButton>

        <AntButton
          type={tab === 'building' ? 'primary' : 'default'}
          onClick={handleChangeTab('building')}
          className={clsx('w-36 h-12 !rounded-none !font-bold !leading-4', {
            'bg-white border border-solid border-[#3163B7] text-[#3163B7] hover:!text-[#3136B7]':
              tab !== 'building'
          })}
        >
          {t('user.buildingList')}
        </AntButton>
      </div>

      <div className="mt-1">
        {tab === 'information' ? (
          <UserInformation idUser={id} />
        ) : (
          <BuildingList idUser={id} />
        )}
      </div>
    </div>
  )
}
