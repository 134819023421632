import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, notification, Typography } from 'antd'
import useMutation from 'hooks/useMutation'
import { CurrentUser } from 'models/User'
import Api from 'services/api'
import { storeIdTokens } from 'services/authAxios'
import useSWR from 'swr'

import Button from 'components/atoms/Button'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

type Props = {
  currentSignIn: { email: string; password: string }
  handleBackToLogin: () => void
}

export default function SignInValidation({
  currentSignIn,
  handleBackToLogin
}: Props) {
  const resendTimeout = 15 * 60
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const resendCountDownRef = useRef<any>(null)

  const [isResendCode, setIsResendCode] = useState(true)
  const [resendCountDown, setResendCountDown] = useState<number>(resendTimeout)

  const { mutate } = useSWR<CurrentUser | null>('v1/users/me', Api.get, {
    revalidateOnMount: false
  })

  const { trigger: triggerVerify, isMutating: isVerifying } =
    useMutation('/v1/auth/verify ')

  const handleSendCode = async (values: any) => {
    if (!isVerifying) {
      triggerVerify(
        {
          data: {
            code: values?.code,
            email: currentSignIn.email,
            password: currentSignIn.password
          }
        },
        {
          onSuccess: (data) => {
            storeIdTokens(data.data)
            mutate()
          },
          onError: (error) => {
            if (
              error?.response?.data?.error?.key === 'err_verify_mfa_code_fail'
            ) {
              notification.error({
                message: t('login.invalidMFACodeError'),
                key: 'verify-error'
              })
            }
          }
        }
      )
    }
  }

  const { trigger: triggerResendVerify, isMutating: isResendingVerify } =
    useMutation('/v1/auth/resend-verify ')

  const handleResendCode = () => {
    if (!isResendCode && !isResendingVerify) {
      setResendCountDown(resendTimeout)
      setIsResendCode(true)
      triggerResendVerify(
        {
          data: {
            email: currentSignIn.email,
            password: currentSignIn.password
          }
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('login.resendMFACode'),
              key: 'resend-verify'
            })
          }
        }
      )
    }
  }

  useEffect(() => {
    if (isResendCode) {
      resendCountDownRef.current = setInterval(() => {
        setResendCountDown((prev) => (prev > 0 ? prev - 1 : prev))
      }, 1000)
    }

    return () => {
      clearInterval(resendCountDownRef.current)
    }
  }, [isResendCode])
  const displayMinutes = Math.floor(resendCountDown / 60)
  const displaySeconds = resendCountDown % 60

  useEffect(() => {
    if (!resendCountDown) {
      setIsResendCode(false)
      clearInterval(resendCountDownRef.current)
      resendCountDownRef.current = null
    }
  }, [resendCountDown])

  return (
    <div className="bg-[#F9FBFF] w-full h-full">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Spinner spinning={isVerifying}>
          <div className="bg-white rounded-[20px] px-[60px] pt-[60px] pb-[32px] shadow-[0_0_10px_0_#00000026] w-[480px]">
            <Title title={t('login.validation')} />
            <Form form={form} onFinish={handleSendCode}>
              <div className="flex flex-col gap-[10px]">
                <Typography className="text-[16px] leading-[26px] text-[#021120]">
                  {t('login.twoFactorValidationGuide')}
                </Typography>

                <Form.Item
                  name="code"
                  className="mb-5"
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]{6}$/,
                      message: t('login.validationCodeError')
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t('common.floor.placeHolder')}
                  />
                </Form.Item>

                <div
                  className={`text-base font-semibold flex gap-2 mb-3 ml-auto ${
                    isResendCode
                      ? 'text-[#a6aaaf] cursor-not-allowed'
                      : 'text-[#3163b7] cursor-pointer'
                  }`}
                  onClick={handleResendCode}
                >
                  <span className="underline underline-offset-4">
                    認証コードを再送信する
                  </span>
                  {isResendCode && (
                    <span>
                      &nbsp;{displayMinutes}分
                      {displaySeconds < 10
                        ? `0${displaySeconds}`
                        : displaySeconds}
                      秒
                    </span>
                  )}
                </div>

                <div className="flex gap-5">
                  <Button block sizing="h-[60px]" onClick={handleBackToLogin}>
                    {t('translation.buttonBack')}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    sizing="h-[60px]"
                  >
                    {t('login.validation')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Spinner>
      </div>
    </div>
  )
}
