import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Pagination, Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import { ListBuilding } from 'models/Building'
import { Role } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'

import Button from 'components/atoms/Button'
import LazyThumbnail from 'components/atoms/LazyThumbnail'
import Spinner from 'components/atoms/Spinner'
import NoImageIcon from 'components/icons/NoImageIcon'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  idUser?: string
}

export default function BuildingList({ idUser }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { profile } = useAuth()

  const [queries, setQueries] = useSearchParams()

  const page = queries.get('page')

  const params = useMemo(() => {
    const { sort, sortOrder, page, ...rest } = Object.fromEntries(
      queries.entries()
    )
    return {
      ...rest,
      sort: sort || 'createdAt',
      sortOrder: sortOrder || OrderByType.descend,
      page: page || 1,
      pageSize: 10,
      id: idUser === 'me' ? `${profile?.id}` : idUser
    }
  }, [idUser, profile?.id, queries])

  const { data: buildings, isValidating } = useSWR<ListBuilding>({
    url: `/v1/buildings`,
    params
  })

  const listBuilding = buildings?.edges?.map((item) => {
    const fullNameUser = `${item.latestEditedBy?.lastName || ''} ${
      item.latestEditedBy?.firstName || ''
    }`
    return {
      ...item,
      buildingStatus: item.status ? t(`building.${item.status}`) : '',
      branchName: item.branch ? (
        <div>
          <Typography>
            {item.branch.name !== 'その他' ? item.branch.name : ''}
          </Typography>
          <Typography>
            {item.branch.serviceStoreName !== 'その他'
              ? item.branch.serviceStoreName
              : ''}
          </Typography>
        </div>
      ) : (
        ''
      ),
      orgName: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.organizationName
            }}
          >
            {item.organizationName}
          </Typography.Paragraph>
        </div>
      ),
      name: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph
            className="!mb-0"
            ellipsis={{
              rows: 2,
              tooltip: item.name
            }}
          >
            {item.name}
          </Typography.Paragraph>
        </div>
      ),
      updatedAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.latestUpdated).format('YYYY/MM/DD HH:mm')}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: fullNameUser
            }}
            className="!mb-0 truncate"
          >
            {fullNameUser}
          </Typography.Paragraph>
        </div>
      ),
      image: item.s3Path ? (
        <LazyThumbnail
          name="thumbnail"
          url={item.thumbnail || item.s3Path}
          className="w-[120px] h-[67.5px] !bg-[#021120]"
          width={120}
          height={67.5}
        />
      ) : (
        <NoImageIcon width={120} height={67.5} />
      ),
      createdAt: (
        <div className="grid grid-cols-1">
          <Typography.Paragraph className="!mb-0">
            {dayjs(item.createdAt).format('YYYY/MM/DD')}
          </Typography.Paragraph>
        </div>
      )
    }
  })

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      if (sorter) {
        queries.set('sort', field)
        queries.set(
          'sortOrder',
          sorter
            ? OrderByType[sorter as keyof typeof OrderByType]
            : OrderByType.descend
        )
      } else {
        queries.delete('sort')
        queries.delete('sortOrder')
      }
      setQueries(queries, { replace: true })
    },
    [queries, setQueries]
  )

  const handleBack = () => {
    if (profile?.role === Role.USER) {
      navigate('/buildings')
      return
    }
    navigate('/users')
  }

  const hiddenPagination = useMemo(
    () =>
      Boolean(
        buildings?.pagination.total &&
          buildings.pagination.total /
            Const.DEFAULT_PAGINATION.defaultPageSize >
            1
      ),
    [buildings?.pagination.total]
  )

  const onPageChange = useCallback(
    (page: number) => {
      queries.set('page', `${page}`)
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="text-[#3163b7]" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="text-[#3163b7]" />
        </div>
      )
    }
    return originalElement
  }

  return (
    <Spinner spinning={isValidating && !buildings}>
      <ExtraTable
        columns={TableColumn.COLUMN_BUILDING}
        data={listBuilding}
        onChange={onTableChange}
        total={buildings?.pagination.total}
        role={profile?.role}
      />

      {hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={+(page || 1)}
            pageSize={10}
            total={buildings?.pagination.total || 0}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}

      <div className="flex pt-6 pb-10">
        <Button
          className="mx-auto"
          sizing="w-[180px] h-[60px]"
          onClick={handleBack}
        >
          {t('translation.buttonBack')}
        </Button>
      </div>
    </Spinner>
  )
}

BuildingList.defaultProps = {
  idUser: undefined
}
