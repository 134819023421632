import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight
} from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { Avatar, Form, notification, Pagination, Typography } from 'antd'
import Const from 'constants/constant'
import TableColumn from 'constants/tableColumn'
import dayjs from 'dayjs'
import useAuth from 'hooks/useAuth'
import useMutation from 'hooks/useMutation'
import { ListUsers } from 'models/User'
import UserInviteStatus from 'pages/user/listUser/components/UserInviteStatus'
import useSWR from 'swr'
import { OrderByType } from 'types'
import Utils from 'utils'

import Button from 'components/atoms/Button'
import ExtraTable from 'components/organisms/ExtraTable'

import FormSearch from './components/FormSearch'
import InviteUser from './components/InviteUser'

export default function UserList() {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [queries, setQueries] = useSearchParams()
  const page = queries.get('page')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { profile } = useAuth()

  const params = useMemo(
    () => ({
      ...Object.fromEntries(queries.entries()),
      page: page || 1,
      pageSize: 10
    }),
    [page, queries]
  )

  const {
    data: users,
    isValidating,
    mutate
  } = useSWR<ListUsers>({
    url: `/v1/users`,
    params
  })

  const hiddenPagination = useMemo(
    () =>
      Boolean(
        users?.pagination.total &&
          users.pagination.total / Const.DEFAULT_PAGINATION.defaultPageSize > 1
      ),
    [users?.pagination.total]
  )

  const { trigger, isMutating } = useMutation('v1/users/invite', {
    onSuccess: () => {
      notification.success({ message: '送信しました' })
      setIsModalOpen(false)
      mutate()
    },
    onError: (error) => {
      Utils.handleError(error, t, form)
    }
  })

  const listUser = useMemo(
    () =>
      users?.edges?.map((item) => {
        const isGuestUser = !item.organization ? 'text-[#E96709]' : ''
        return {
          ...item,
          role: <span className={isGuestUser}>{t(`user.${item.role}`)}</span>,
          organizationName: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className={`!mb-0 ${isGuestUser}`}
                ellipsis={{
                  rows: 2,
                  tooltip: item.organization?.name || item.companyName
                }}
              >
                {item.organization?.name || item.companyName}
              </Typography.Paragraph>
            </div>
          ),
          email: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className={`!mb-0 ${isGuestUser}`}
                ellipsis={{
                  rows: 2,
                  tooltip: item.email
                }}
              >
                {item.email}
              </Typography.Paragraph>
            </div>
          ),
          avatar: (
            <Avatar
              className="min-w-[2.5rem] text-black font-light"
              size="large"
            >
              {item.lastName.split('')[0]}
              {item.firstName.split('')[0]}
            </Avatar>
          ),
          name: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className={`!mb-0 ${isGuestUser}`}
                ellipsis={{
                  rows: 2,
                  tooltip: `${item.lastName} ${item.firstName}`
                }}
              >
                <div>{`${item.lastName} ${item.firstName}`} </div>
              </Typography.Paragraph>

              {item.status === 'INVITED' && (
                <UserInviteStatus user={item} mutate={mutate} />
              )}
            </div>
          ),
          latestLoginAt: (
            <span className={isGuestUser}>
              {item.latestLoginAt !== '0001-01-01T00:00:00Z'
                ? dayjs(item.latestLoginAt).format('YYYY-MM-DD HH:mm')
                : ''}
            </span>
          ),
          invitedBy: (
            <div className="grid grid-cols-1">
              <Typography.Paragraph
                className={`!mb-0 ${isGuestUser}`}
                ellipsis={{
                  rows: 2,
                  tooltip: item.invitedByEmail
                }}
              >
                {item.invitedByEmail}
              </Typography.Paragraph>
            </div>
          )
        }
      }),
    [mutate, t, users?.edges]
  )

  const listRole = profile ? Const.ROLES[profile.role] : []

  const onTableChange = useCallback(
    (sorter: string, field: string) => {
      queries.set('sort', sorter ? field : 'id')
      queries.set(
        'sortOrder',
        sorter
          ? OrderByType[sorter as keyof typeof OrderByType]
          : OrderByType.descend
      )
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const handleSearch = (value: any) => {
    Object.keys(value).forEach((key) => {
      if (queries.get(key) && !value[key]) {
        queries.delete(key)
      }
      value[key] && queries.set(key, value[key])
    })
    queries.set('page', '1')
    setQueries(queries)
  }

  const handleOk = async (value: any) => {
    if (isMutating) return
    await trigger({ data: value })
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const onPageChange = useCallback(
    (page: number) => {
      queries.set('page', `${page}`)
      setQueries(queries)
    },
    [queries, setQueries]
  )

  const itemRender = (_: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div className="icon-prev flex items-center justify-center">
          <MdKeyboardDoubleArrowLeft className="text-[#3163b7]" />
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div className="icon-next flex items-center justify-center">
          <MdKeyboardDoubleArrowRight className="text-[#3163b7]" />
        </div>
      )
    }
    return originalElement
  }

  return (
    <>
      {isModalOpen && (
        <InviteUser
          form={form}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}

      <div className="flex justify-between pb-5 items-center">
        <div className="font-bold text-xl">{t('user.title')}</div>
        <Button
          type="primary"
          className="ml-14"
          sizing="w-40 h-10"
          onClick={() => setIsModalOpen(true)}
        >
          {t('user.buttonCreate')}
        </Button>
      </div>
      <div className="px-10 py-5 flex items-center bg-white rounded-lg shadow-sm mb-5 lg:px-[30px]">
        <FormSearch handleSearch={handleSearch} listRole={listRole} />
      </div>
      <ExtraTable
        isLoading={isValidating && !users}
        columns={TableColumn.COLUMN_USER}
        data={listUser}
        onChange={onTableChange}
        total={users?.pagination.total}
        refetch={mutate}
      />

      {hiddenPagination ? (
        <div className="flex justify-center">
          <Pagination
            showSizeChanger={false}
            className="my-4 custom-pagination"
            current={+(page || 1)}
            pageSize={10}
            total={users?.pagination.total || 0}
            onChange={onPageChange}
            itemRender={itemRender}
          />
        </div>
      ) : null}
    </>
  )
}
