import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import Const from 'constants/constant'
import { ListOrganizations } from 'models/Organization'
import { Organization } from 'models/User'

import { ParamQueryOrganization } from './listOrganization'
import { SelectOrganization } from './listUser'

export type UserDetailManageOrganization = {
  manageModal: ManageModal
  setOrganizationModal: React.Dispatch<React.SetStateAction<ManageModal>>
  manageOrganizations?: Organization[]
  handleCloseModal: () => void
}

export type ManageModal = {
  isOpen: boolean
  organizations: number[]
}

export type InviteUserModalOrganization = {
  organizations: ListOrganizations | undefined
  previousSelect: SelectOrganization | undefined
  setPreviousSelect: Dispatch<SetStateAction<SelectOrganization | undefined>>
  queries: ParamQueryOrganization | null
  setQueries: Dispatch<SetStateAction<ParamQueryOrganization | null>>
  handleOk: (value: SelectOrganization) => void
  handleCancel: () => void
  roleUser: string
  setOrganization: any
  organizationId?: MutableRefObject<SelectOrganization | undefined>
}

export enum UserStatus {
  INVITED = 'INVITED',
  CONFIRMED = 'CONFIRMED'
}

export type DeleteUserError = keyof typeof Const.DELETE_USER_ERROR

export type UpdateUserError = keyof typeof Const.UPDATE_USER_ERROR
